import {
    isValidObject,
    isValidString,
    isWindowValid,
    validBoolean,
    whichString
} from '../helpers/valid';

/**
 * @class pfm-shared
 * @description A mixin that provides common functionality to PFM UI and PFM Container components
 */
export default {
    computed: {
        /**
         * @property {boolean} canLog Is debug enabled and is there a logger?
         */
        canLog() {
            const { pfmDebugEnabled, logger } = this;

            return pfmDebugEnabled && isValidObject(logger);
        },
        /**
         * @property {string} componentVersion A <package name>@<version> string for the component
         */
        componentVersion() {
            const { mwcComponentName, version } = this;

            return `${mwcComponentName}@${version}`;
        },
        /**
         * @property {boolean} pfmDebugEnabled A flag to detect if we can safely log information
         */
        pfmDebugEnabled() {
            const { settings = {} } = this;

            return isWindowValid()
                ? validBoolean(window.pfmDebugEnabled, settings.debugEnabled)
                : validBoolean(settings.debugEnabled);
        },
        /**
         * @property {string} pfmLogId An identifier to use when logging
         */
        pfmLogId() {
            const { mwcId, name } = this;

            return whichString([mwcId, name, 'pfmComponent']);
        }
    },
    data() {
        return {
            lastDataRefresh: '',
            mwcComponentName: '',
            version: ''
        };
    },
    methods: {
        /**
         * @function getMSAT
         * @description Generates a MorningStar Automation Tag identifer for an element
         * @param {string} section The section of the component this element belongs to
         * @param {string} element An identifier for the element, unique with the section
         * @returns {string} The MSAT
         */
        getMSAT(section, element) {
            const { pfmLogId } = this;
            const parts = [];

            if (isValidString(pfmLogId)) {
                parts.push(pfmLogId);
            }

            if (isValidString(section)) {
                parts.push(section);
            }

            if (isValidString(element)) {
                parts.push(element);
            }

            return parts.join('-');
        },
        /**
         * @function getUniqueID
         * @description Generates unique `id` values for HTML elements
         * @param {string} prefix The prefix for the ID, to make its purpose clear
         * @returns {string} The unique ID
         */
        getUniqueID(prefix) {
            const idPrefix = isValidString(prefix)
                ? prefix
                : 'ID_';

            return `${idPrefix}${Math.random()
                .toString(36)
                .substr(2, 9)}`;
        },
        /**
         * @function pfmError
         * @description Will output an error if debugging is enabled
         * @param {string} message The message to output
         * @param {*} trace The stack track or meaningful object
         * @returns {void}
         */
        pfmError(message, trace) {
            const { canLog, logger, pfmLogId } = this;

            if (canLog === true) {
                logger.error(pfmLogId, message, trace);
            }
        },
        /**
         * @function pfmError
         * @description Will output a log if debugging is enabled
         * @param {string} message The message to output
         * @param {*} trace The stack track or meaningful object
         * @returns {void}
         */
        pfmLog(message, trace) {
            const { canLog, logger, pfmLogId } = this;

            if (canLog === true) {
                logger.info(pfmLogId, message, trace);
            }
        },
        /**
         * @function pfmTrace
         * @description Will output a trace if debugging is enabled
         * @param {string} message The message to output
         * @param {*} trace The stack track or meaningful object
         * @returns {void}
         */
        pfmTrace(message, trace) {
            const { canLog, logger, pfmLogId } = this;

            if (canLog === true) {
                logger.trace(pfmLogId, message, trace);
            }
        },
        /**
         * @function pfmWarn
         * @description Will output a warning if debugging is enabled
         * @param {string} message The message to output
         * @param {*} trace The stack track or meaningful object
         * @returns {void}
         */
        pfmWarn(message, trace) {
            const { canLog, logger, pfmLogId } = this;

            if (canLog === true) {
                logger.warn(pfmLogId, message, trace);
            }
        },
        /**
         * @function setLastDataRefresh
         * @description Updates the `lastDataRefresh` data prop, can be used to force refresh
         * @returns {void}
         */
        setLastDataRefresh() {
            const now = new Date();
            const utc = now.toUTCString();

            this.lastDataRefresh = utc;
        }
    },
    mounted() {
        this.setLastDataRefresh();
        this.pfmLog('mounted.');
    },
    props: {
        debugEnabled: {
            default: false,
            type: [String, Boolean]
        },
        loading: {
            default: false,
            type: [String, Boolean]
        }
    }
};
