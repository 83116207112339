<template>
    <div
        class="mds-alert pfm-account-list__alert"
        :class="cssClass"
        role="alert"
        aria-atomic="true"
        aria-labelledby="accListErrorMessage">
        <div class="mds-alert__cell-icon">
            <mds-icon
                aria-hidden="true"
                :name="iconName" />
        </div>
        <div class="mds-alert__cell-body">
            <aside class="mds-alert__content">
                <strong
                    v-if="title"
                    v-html="title"
                    class="mds-alert__title"></strong>
                <div
                    v-if="message"
                    v-html="message"
                    id="accListErrorMessage"
                    class="mds-alert__message"></div>
                <mds-button
                    :aria-label="actionText"
                    v-if="actionText && displayActionItems"
                    v-html="actionText"
                    :disabled="readMode"
                    @click="emitActionClicked"
                    :data-msat="getMSAT('action-button')"
                    variation="secondary" />
                <mds-button
                    :aria-label="secondaryActionText"
                    v-if="secondaryActionText && displayActionItems"
                    v-html="secondaryActionText"
                    :disabled="readMode"
                    @click="emitSecondaryActionClicked"
                    :data-msat="getMSAT('secondary-action-button')"
                    variation="secondary" />
            </aside>
        </div>
    </div>
</template>

<script>
import { MdsButton } from '@mds/button';
import MdsIcon from '@mds/icon';
import PfmSharedMixin from 'pfm-shared/mixins/pfm-shared';

export default {
    components: {
        MdsButton,
        MdsIcon
    },
    computed: {
        cssClass() {
            const {
                type
            } = this;
            const cssClasses = [];

            if (['error', 'success', 'warning'].indexOf(type) > -1) {
                cssClasses.push(`mds-alert--${type}`);
            }

            return cssClasses.join(' ');
        },
        iconName() {
            const { type } = this;
            const icons = {
                error: 'alert',
                informational: 'info-circle',
                success: 'check',
                warning: 'alert'
            };
            const icon = icons[type] || 'alert';

            return icon;
        }
    },
    methods: {
        emitActionClicked() {
            const _this = this;
            const { displayID } = _this;

            _this.$emit('pfm-action-clicked', displayID);
        },
        emitSecondaryActionClicked() {
            const _this = this;
            const { displayID } = _this;

            _this.$emit('pfm-secondary-action-clicked', displayID);
        }
    },
    mixins: [PfmSharedMixin],
    name: 'pfm-account-list-alert',
    props: {
        actionText: {
            default: '',
            required: false,
            type: String
        },
        displayActionItems: {
            default: true,
            required: false,
            type: Boolean
        },
        config: {
            default: () => ({}),
            type: [String, Object]
        },
        message: {
            required: false,
            type: String
        },
        mwcId: {
            required: true,
            type: String
        },
        readMode: {
            default: false,
            type: Boolean
        },
        secondaryActionText: {
            default: '',
            required: false,
            type: String
        },
        title: {
            required: false,
            type: String
        },
        type: {
            default: 'error',
            required: false,
            type: String
        }
    }
};
</script>

<style lang="scss" module>
:global {
    .pfm-account-list {
        &__alert {
            margin: 24px 0;
            text-align: left;

            + .pfm-account-list {
                &__alert {
                    margin: 0 0 24px;
                }
            }
        }
    }
}

:global(.pfm-account-list__alert) {
    .mds-button {
        +.mds-button {
            margin-left: 8px;
        }
    }
}
</style>
