/* eslint-disable max-len */
export default {
    badAccountCheckLater: 'We are having trouble accessing {{institutionName}}. Check back later.',
    badAccountVerifyAccountNum: 'We are having trouble accessing {{institutionName}}. Check back later.',
    badAccountVerifyAccountNumShort: "We can't connect to your {{institutionName}} account right now. Try reentering your account number.",
    cannotConnectBadLoginPw: 'We cannot connect to {{institutionName}}. The {{loginTerm}} or {{pwTerm}} is incorrect. Re-authenticate.',
    cannotConnectBadLoginPwIsac: 'We cannot connect to {{institutionName}}. The {{loginTerm}}, {{pwTerm}} or answer to a security question is incorrect. Re-authenticate.',
    cannotConnectBadLoginPwSqa: 'We cannot connect to {{institutionName}}. The {{loginTerm}}, {{pwTerm}} or answer to a security question is incorrect. Re-authenticate.',
    cannotConnectBadLoginPwSqaIsac: 'We cannot connect to {{institutionName}}. The {{loginTerm}}, {{pwTerm}}, answer to a security question or security code is incorrect. Re-authenticate.',
    cannotConnectMissingAcctNum: 'We are having trouble accessing {{institutionName}}. Check back later.',
    cannotConnectMissingAcctNumShort: "We can't connect to your {{institutionName}} account right now. Complete entering your account number.",
    cannotConnectMissingLoginPW: 'Authenticate at {{institutionName}} to connect.',
    cannotConnectNotAuthorized: 'We cannot connect to {{institutionName}}. Authorize access to connect.',
    cannotConnectOauthAcctNotAuthorized: 'We are having trouble accessing {{institutionName}}. Check back later.',
    cannotConnectOauthNotConfigured: 'Further configuration is required to connect to {{institutionName}}. Contact your aggregation provider for assistance.',
    cannotConnectUserActionPersonalInfo: '{{institutionName}} requires you to update or verify personal information or preferences on their website and then re-authenticate.',
    cannotConnectUserActionSecurity: '{{institutionName}} requires you to set up additional security features on their website and then re-authenticate.',
    cannotConnectUserActionUserAgreement: '{{institutionName}} requires you to accept a user agreement on their website and then re-authenticate.',
    cannotConnectUserActionOnetimeCode: 'To allow the aggregation service to access your accounts, please login on the {{institutionName}} website and turn off the setting that requires identity verification at every login.',
    cannotLoadDataCheckLater: 'We are having trouble accessing {{institutionName}}. Check back later.',
    cannotLoadDataCheckLaterShort: "We can't connect to your {{institutionName}} account right now. Please try adding the account later.",
    cannotVerifyAcctNumShort: "We can't connect to your {{institutionName}} account right now. Try reentering your account number.",
    cannotVerifyFiLimitation: 'We cannot verify your credentials due to a limitation with {{institutionName}}. Check back later.',
    cannotVerifyFiLimitationShort: "The institution doesn't allow account linking.",
    credHasNoAccounts: 'No accounts have been connected at {{institutionName}}. Connect accounts.',
    failedVerifyAccount: "We can't connect to your {{institutionName}} account right now. Try reentering your account number.",
    filoginCannotConnectBadLoginPw: "Couldn't connect. Check your login or password.",
    fiNotYetSupported: "Can't link accounts at this institution.",
    fiNotYetSupportedShort: "Sorry, we can't link accounts here just yet.",
    fiRequestMissingElements: 'Additional inputs are required before this {{institutionName}} support request can be processed.',
    incorrectCredentials: "We can't connect to your {{institutionName}} account right now. Try reentering your information.",
    isacCannotConnectBadIsac: "The security code wasn't right. Check it again and have another go.",
    noConnectAttemptYet: 'Authenticate at {{institutionName}} to connect.',
    specifiedAccountNotFound: "We can't connect to your {{institutionName}} account right now. Try reentering your account number.",
    sqaCannotConnectBadSqa: "Couldn't connect. Must be a typo in your security question. Try entering it again."
};
/* eslint-enable max-len */
