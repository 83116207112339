<template>
    <pop-over
        :id="id"
        :is-popped="isOpen"
        :position="position"
        :size="size"
        @change="pop">
        <button
            slot="clickTrigger"
            class="mds-button mds-button--icon-only"
            aria-haspopup="true"
            :aria-expanded="isOpen ? 'true' : 'false'"
            :aria-controls="id" >
            <question-circle-icon
                class="mds-icon mds-button__icon"
                aria-hidden="true" />
        </button>
        <slot />
    </pop-over>
</template>

<script>
// @todo change the button to MDS button and add a class name to it
import PopOver from 'mds-vue/src/components/PopOver';
import QuestionCircleIcon from 'morningstar-design-system/dist/mds.svg?question-circle'; // eslint-disable-line
export default {
    name: 'info-popover',
    components: {
        QuestionCircleIcon,
        PopOver
    },
    props: {
        size: {
            type: String,
            default: '200px'
        },
        position: {
            type: String,
            default: 'bottom-left'
        },
        id: {
            type: String,
            default: null
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    methods: {
        pop(newValue) {
            this.isOpen = newValue;
        }
    }
};
</script>
