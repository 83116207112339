var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cwp-notification"},_vm._l((_vm.itemList),function(items,index){return _c('div',{key:index,class:{
            'mds-notification--bottom-right': index === 'bottom',
            'mds-notification--top-center mds-notification--below-masthead-tall': index === 'top',
            'mds-notification--inline': index === 'none',
            'mds-notification mds-notification--active': true
        }},_vm._l((items),function(item,index){return _c('div',{key:index,class:{
                'mds-notification__item--dismissible': item.isDismissible,
                'mds-notification__item--tinted': item.isTinted,
                'mds-notification__item--error': item.type === 'error',
                'mds-notification__item--success': item.type === 'success',
                'mds-notification__item--warning': item.type === 'warning',
                'mds-notification__item mds-notification__item--visible': true
            },attrs:{"role":item.buttonText ? 'alertdialog': 'alert'}},[_c('div',{staticClass:"mds-notification__status"},[(item.type === 'info')?_c('info-circle-icon',{staticClass:"mds-icon mds-notification__icon",attrs:{"title":"Information"}}):_vm._e(),(item.type === 'success')?_c('check-icon',{staticClass:"mds-icon mds-notification__icon",attrs:{"title":"Success"}}):_vm._e(),(item.type === 'warning')?_c('alert-icon',{staticClass:"mds-icon mds-notification__icon",attrs:{"title":"Warning"}}):_vm._e(),(item.type === 'error')?_c('alert-icon',{staticClass:"mds-icon mds-notification__icon",attrs:{"title":"Error"}}):_vm._e()],1),_c('div',{staticClass:"mds-notification__body"},[_c('div',{staticClass:"mds-notification__content"},[_c('p',{staticClass:"mds-notification__message",domProps:{"innerHTML":_vm._s(item.message)}}),(item.list)?_c('ul',{staticClass:"mds-notification__list"},_vm._l((item.list),function(listItem,index){return _c('li',{key:index,staticClass:"mds-notification__list-item"},[_vm._v(_vm._s(listItem.name))])}),0):_vm._e(),(item.buttonText)?_c('button',{staticClass:"mds-button mds-button--small mds-button--secondary",attrs:{"disabled":_vm.isIE || _vm.readMode,"type":"button"},on:{"click":function($event){return _vm.onClickBtn(item)}}},[_vm._v(" "+_vm._s(item.buttonText)+" ")]):_vm._e()])]),(item.isDismissible)?_c('button',{staticClass:"mds-button mds-notification__dismiss-button mds-button--small mds-button--icon-only",attrs:{"type":"button"},on:{"click":function($event){return _vm.onClickClose(item)}}},[_c('remove-icon',{staticClass:"mds-icon mds-button__icon mds-button__icon--right",attrs:{"title":"close"}})],1):_vm._e()])}),0)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }