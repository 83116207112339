import { isValidString } from './valid';

/**
 * @function stringToTitleCase
 * @description Converts a string to title case
 * @param {*} value The value to convert
 * @returns {string} The converted string or empty if an invalid string was passed
 */
export function stringToTitleCase(value) {
    if (!isValidString(value)) {
        return '';
    }

    const ignoredWords = [
        'a',
        'an',
        'and',
        'as',
        'at',
        'but',
        'by',
        'for',
        'if',
        'in',
        'nor',
        'of',
        'off',
        'on',
        'or',
        'per',
        'so',
        'the',
        'to',
        'up',
        'via',
        'yet'
    ];

    const lowerCaseString = value.toLowerCase();
    const stringParts = lowerCaseString.split(' ');
    const stringPartsCount = stringParts.length;
    let beforeColon = false;

    for (let i = 0; i < stringPartsCount; i += 1) {
        const stringPart = stringParts[i];
        const isIgnored = ignoredWords.indexOf(stringPart) > -1;

        // AP title case says lowercase certain words unless they appear after a colon
        if (i === 0 || (beforeColon === true || isIgnored === false)) {
            stringParts[i] = stringParts[i].charAt(0).toUpperCase() + stringParts[i].slice(1);
        }

        beforeColon = stringPart.charAt(stringPart.length - 1) === ':';
    }

    return stringParts.join(' ');
}

export default null;
