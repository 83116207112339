/**
 * @function doubleRequestAnimationFrame
 * @description double RequestAnimationFrame, To wait for a browser repaint
 * @param {function} callback
 */

function doubleRequestAnimationFrame(callback) {
    requestAnimationFrame(() => {
        requestAnimationFrame(callback);
    });
}

/**
 * @function forceNextTick
 * @description Wrapper for doubleRequestAnimationFrame to promisify
 * @param {function} callback
 */

function forceNextTick(callback) {
    if (callback && typeof callback === 'function') {
        doubleRequestAnimationFrame(callback);
    } else {
        return new Promise(resolve => {
            doubleRequestAnimationFrame(resolve);
        });
    }
    return false;
}

export default forceNextTick;
