<template>
    <div
        v-if="account"
        class="pfm-account-detail__container"
        @click="onSupportElementClick">
        <div class="pfm-account-detail">
            <div class="pfm-account-detail__action">
                <mds-button
                    aria-labelledby="ariaDismissAccountBtn"
                    aria-describedby="ariaDismissAccountBtn"
                    @click="emitDismissAccount"
                    :data-msat="getMSAT('dismiss-account-button')"
                    ref="dismissAccountDetailBtn"
                    icon="caret-left"
                    type="button"
                    variation="icon-only">
                    <span class="visually-hidden">{{labels.backToAccountList}}</span>
                </mds-button>
                <p id="ariaDismissAccountBtn" hidden>{{labels.backToAccountList}}, {{labels.ariaDismissAccountBtn}}</p>
            </div>
            <div class="pfm-account-detail__content">
                <div
                    v-html="account.title"
                    class="pfm-account-detail__title"></div>
                <div class="pfm-account-detail__sub-title">
                    <div
                        v-if="!isPendingRequest"
                        :aria-description="labels.ariaInstitutionName"
                        v-html="account.institutionName"
                        class="pfm-account-detail__institution"></div>
                    <div
                        v-html="account.displayAccountNumber"
                        :aria-description="labels.ariaAccountFourDigit"
                        class="pfm-account-detail__number"></div>
                </div>
            </div>
        </div>
        <mds-alert
            v-if="accountAlert && isCashflowEnabled"
            class="pfm-account-detail__alert"
            :persistent="true"
            :text="accountAlert.text"
            :variation="accountAlert.variation" />
        <notification
            v-if="account.hasError"
            class="pfm-account-detail__notification"
            :content-list="notifications"
            :read-mode="readMode"
            :data-msat="getMSAT('account-error')"
            @notification-secondary-action="emitUpdateCredential" />
        <mds-button-container
            class="pfm-account-detail__button-container"
            ref="accDetailBtnContainer"
            aria-label="Action">
            <mds-button
                v-if="canUnlink && !isPendingRequest"
                id="pfmUnlinkAccountBtn"
                aria-labelledby="ariaUnlinkAccountButton"
                aria-describedby="ariaUnlinkAccountButton"
                @click="emitShowUnlinkAccount"
                :data-msat="getMSAT('unlink-account-button')"
                :disabled="!useBaa || readMode"
                icon="lock-open"
                type="button"
                variation="secondary">{{labels.unlinkAccount}}</mds-button>
            <mds-button
                v-if="canDelete && !isPendingRequest"
                id="pfmDeleteAccountBtn"
                ref="deleteAccountBtn"
                aria-labelledby="ariaDeleteAccountButton"
                aria-describedby="ariaDeleteAccountButton"
                @click="emitShowDeleteAccount"
                :data-msat="getMSAT('delete-account-button')"
                :disabled="!useBaa || readMode"
                icon="trash"
                type="button"
                variation="secondary">{{labels.deleteAccount}}</mds-button>
            <mds-button
                v-if="isPendingRequest"
                id="pfmEditRequestBtn"
                aria-labelledby="ariaEditRequestBtn"
                aria-describedby="ariaEditRequestBtn"
                @click="emitUpdateCredential"
                :disabled="readMode"
                type="button"
                variation="secondary">{{labels.editRequest}}</mds-button>
            <mds-button
                v-if="isPendingRequest"
                id="pfmDeleteCredentialsBtn"
                aria-labelledby="ariaWithdrawRequestBtn"
                aria-describedby="ariaWithdrawRequestBtn"
                @click="emitDeleteCredential"
                :disabled="readMode"
                type="button"
                variation="secondary">{{labels.withdrawRequest}}</mds-button>
        </mds-button-container>
        <p id="ariaUnlinkAccountButton" hidden>{{labels.unlinkAccount}}</p>
        <p id="ariaDeleteAccountButton" hidden>{{labels.deleteAccount}}</p>
        <p id="ariaEditRequestBtn" hidden>{{labels.editRequest}}</p>
        <p id="ariaWithdrawRequestBtn" hidden>{{labels.withdrawRequest}}</p>
    </div>
</template>

<script>
import MdsAlert from '@mds/alert';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import Notification from 'cwp-dev-shared/components/Notification';
import { isValidObject } from 'pfm-shared/helpers/valid';
import PfmSharedMixin from 'pfm-shared/mixins/pfm-shared';
import { DISPLAY_TYPES } from 'pfm-shared/helpers/display-type';
import { isValidElement } from 'pfm-shared/helpers/elements';
import forceNextTick from 'pfm-shared/helpers/vueForceNextTick';
import { canUnlinkAccount, compareAccounts } from 'pfm-shared/helpers/account';
import AccountListMixin from '../mixins/account-list';

export default {
    components: {
        MdsAlert,
        MdsButton,
        MdsButtonContainer,
        Notification
    },
    computed: {
        accountAlert() {
            const {
                deletedAccountMatches,
                labels,
                unlinkedAccountMatches,
                account
            } = this;

            if (!deletedAccountMatches && !unlinkedAccountMatches) {
                return null;
            }

            const alertDetails = {
                variation: 'informational'
            };

            if (deletedAccountMatches || account.displayTypeName === DISPLAY_TYPES.INVESTMENTS) {
                alertDetails.text = labels.accountDetail.deleted;
                this.emitDismissAccount();
            } else if (unlinkedAccountMatches) {
                alertDetails.text = labels.accountDetail.unlinked;
            }

            return alertDetails;
        },
        canDelete() {
            const { deletedAccountMatches } = this;

            if (deletedAccountMatches) {
                return false;
            }

            return true;
        },
        canUnlink() {
            const { account, unlinkedAccountMatches } = this;

            if (unlinkedAccountMatches) {
                return false;
            }

            return canUnlinkAccount(account)
                && account.displayTypeName !== DISPLAY_TYPES.INVESTMENTS
                && this.isCashflowEnabled
                && account.source !== 'unlinked';
        },
        deletedAccountMatches() {
            const {
                account,
                deletedAccount
            } = this;

            return compareAccounts({
                ...account,
                dismissed: false
            }, deletedAccount);
        },
        notifications() {
            const { account, labels, showSupportModal } = this;
            const {
                credentialId,
                errorButtonLabel,
                errorMessage,
                errorType,
                hasError,
                institutionName,
                unifiedStatusInfoType
            } = account;
            const id = credentialId;
            const name = institutionName;
            const userResolvable = errorType !== 'NOT_USER_RESOLVABLE';

            if (!hasError) {
                return [];
            }

            const error = {
                id,
                name,
                unifiedStatusInfoType,
                autoHide: false,
                buttonText: userResolvable ? errorButtonLabel : '',
                isDismissible: false,
                isTinted: true,
                origin: this.$options._componentTag,
                position: 'none',
                timeout: 10000,
                type: userResolvable ? 'error' : 'warning'
            };

            const supportMessage = !showSupportModal
                ? ''
                : ` ${labels.contactSupport}`;

            error.message = `${errorMessage}${supportMessage}`;

            return [error];
        },
        unlinkedAccountMatches() {
            const {
                account,
                unlinkedAccount
            } = this;

            return compareAccounts({
                ...account,
                dismissed: false
            }, unlinkedAccount);
        }
    },
    methods: {
        emitDismissAccount() {
            const {
                deletedAccount,
                unlinkedAccount
            } = this;

            if (isValidObject(deletedAccount)) {
                deletedAccount.dismissed = true;
            }

            if (isValidObject(unlinkedAccount)) {
                unlinkedAccount.dismissed = true;
            }

            this.$emit('pfm-dismiss-account');
        },
        emitShowDeleteAccount() {
            const { account } = this;

            this.$emit('pfm-show-delete-account', account);
        },
        emitShowSupportModal() {
            const { account } = this;

            this.$emit('pfm-show-support-modal', {
                accountId: account.id,
                credentialId: account.institutionId
            });
        },
        emitShowUnlinkAccount() {
            const { account } = this;

            this.$emit('pfm-show-unlink-account', account);
        },
        emitUpdateCredential() {
            this.$emit('pfm-update-credential', {
                id: this.account.credentialId
            });
        },
        emitDeleteCredential() {
            this.$emit('pfm-delete-credential', {
                id: this.account.credentialId
            });
        },
        onSupportElementClick(e) {
            const { target } = e || {};

            // Only emit the event if a support link is clicked
            if (isValidElement(target) && target.dataset.pfmSupport) {
                this.emitShowSupportModal();
            }
        },
        focusDismissAccountDetailBtn() {
            forceNextTick(() => {
                const dismissAccountDetailBtn = this.$refs.dismissAccountDetailBtn.$el;
                if (!dismissAccountDetailBtn) { return; }
                dismissAccountDetailBtn.focus();
            });
        },
        buttonContainerRole() {
            if (this.canUnlink) {
                this.$refs.accDetailBtnContainer.$el.setAttribute('role', 'group');
            }
        }
    },
    mixins: [AccountListMixin, PfmSharedMixin],
    mounted() {
        this.$nextTick(this.bindSupportElements);
        this.focusDismissAccountDetailBtn();
        this.buttonContainerRole();
    },
    name: 'pfm-account-list-account-detail',
    props: {
        account: {
            type: Object,
            default: null
        },
        deletedAccount: {
            default: null,
            type: Object
        },
        labels: {
            default: () => ({}),
            type: Object
        },
        readMode: {
            default: false,
            type: Boolean
        },
        showSupportModal: {
            default: false,
            type: Boolean
        },
        unlinkedAccount: {
            default: null,
            type: Object
        },
        useBaa: {
            default: true,
            type: Boolean
        },
        isCashflowEnabled: {
            default: true,
            type: Boolean
        },
        isPendingRequest: {
            default: false,
            type: Boolean
        }
    }
};
</script>

<style lang="scss" module>
@import '~cwp-dev-shared/styles/variables';

@mixin pfm-account-reset-space ($margin: 0, $padding: 0) {
    margin: $margin;
    padding: $padding;
}

:global {
    .pfm-account-detail {
        display: flex;
        margin-top: $mds-space-3-x;

        &__action {
            margin-right: $mds-space-2-x;
            width: $mds-space-2-x;

            [role=icon] {
                border: none;
            }
        }

        &__alert,
        &__notification {
            margin-top: $mds-space-inset-3-x;
        }

        &__button-container {
            margin-top: $mds-space-4-x;
        }

        &__content {
            flex-grow: 2;
            overflow: hidden;
        }

        &__institution {
            overflow: hidden;
            text-overflow: clip;
            white-space: nowrap;
        }

        &__number {
            white-space: nowrap;
        }

        &__sub-title {
            @include mds-body-text-m();
            color: $mds-text-color-secondary-on-light;
        }

        &__title {
            @include mds-level-5-heading();
            @include pfm-account-reset-space();
        }

        .visually-hidden:not(:focus):not(:active) {
            clip: rect(0 0 0 0);
            clip-path: inset(100%);
            height: 1px;
            overflow: hidden;
            position: absolute;
            white-space: nowrap;
            width: 1px;
        }
    }
    .pfm-account-detail__notification {
        .mds-notification__item--error,
        .mds-notification__item--warning {
            &.mds-notification__item--tinted .mds-notification__body {
                background-color: #fff;
            }
        }
    }
}
</style>
