import { isValidString } from './valid';
import { stringToTitleCase } from './format';

/**
 * @property {object} DISPLAY_TYPES A collection of display types to group accounts by
 * @const
 */
export const DISPLAY_TYPES = {
    BANKING: 'displayTypes.banking',
    CORPORATE_OWNERSHIP: 'displayTypes.corporateOwnership',
    CREDIT_CARDS: 'displayTypes.creditCards',
    INSURANCE: 'displayTypes.insurance',
    INVESTMENTS: 'displayTypes.investments',
    LOANS: 'displayTypes.loans',
    MORTGAGE: 'displayTypes.mortgage',
    OTHER_ACCOUNTS: 'displayTypes.otherAccounts',
    PENSION_BENEFITS: 'displayTypes.pensionBenefits',
    PROPERTY: 'displayTypes.property',
    UNKNOWN: 'displayTypes.unknown'
};

/**
 * @property {Map} DISPLAY_TYPE_ORDER A collection of display types and their ordinal for sequencing
 * @const
 */
export const DISPLAY_TYPE_ORDER = new Map([
    [DISPLAY_TYPES.BANKING, 0],
    [DISPLAY_TYPES.CORPORATE_OWNERSHIP, 3],
    [DISPLAY_TYPES.CREDIT_CARDS, 5],
    [DISPLAY_TYPES.INSURANCE, 8],
    [DISPLAY_TYPES.INVESTMENTS, 1],
    [DISPLAY_TYPES.LOANS, 6],
    [DISPLAY_TYPES.MORTGAGE, 7],
    [DISPLAY_TYPES.OTHER_ACCOUNTS, 9],
    [DISPLAY_TYPES.PENSION_BENEFITS, 4],
    [DISPLAY_TYPES.PROPERTY, 2]
]);

/**
 * @function isInvestmentAccountType
 * @description Determines if an `accountType` is an investment account type
 * @param {string} accountType The BAA account type value
 * @returns {boolean} Is it an investment account type?
 */
export function isInvestmentAccountType(accountType) {
    const investmentAccountTypes = [
        'insurance_annuity',
        'insurance_lifeinsurance',
        'insurance_other',
        'investment_401a',
        'investment_401k',
        'investment_403b',
        'investment_457b',
        'investment_529',
        'investment_brokerage',
        'investment_cashmanagement',
        'investment_coverdell',
        'investment_ira',
        'investment_keogh',
        'investment_mutualfund',
        'investment_other',
        'investment_pension',
        'investment_prepaidtuition',
        'investment_profitshare',
        'investment_retirement',
        'investment_rothira',
        'investment_sarsep',
        'investment_savingsbond',
        'investment_sepira',
        'investment_treasurybond',
        'investment_trust',
        'investment_ugma',
        'investment_utma',
        'null',
        'other',
        'unknown'
    ];

    const accountTypeInLowerCase = (accountType || '').toLowerCase();
    return investmentAccountTypes.indexOf(accountTypeInLowerCase) > -1;
}

/**
 * @function getAccountTypeMapping
 * @description Maps ByAllAccounts `accountType` properties to a display type
 * @param {string} accountType The BAA account type value
 * @returns {string} The display type
 */
export function getAccountTypeMapping(accountType) {
    const accountTypeMap = new Map([
        ['banking_cd', DISPLAY_TYPES.BANKING],
        ['banking_checking', DISPLAY_TYPES.BANKING],
        ['banking_creditline', DISPLAY_TYPES.LOANS],
        ['banking_deposit', DISPLAY_TYPES.BANKING],
        ['banking_moneymarket', DISPLAY_TYPES.BANKING],
        ['banking_other', DISPLAY_TYPES.BANKING],
        ['banking_savings', DISPLAY_TYPES.BANKING],
        ['creditcard', DISPLAY_TYPES.CREDIT_CARDS],
        ['healthaccount_fsa', DISPLAY_TYPES.BANKING],
        ['healthaccount_hra', DISPLAY_TYPES.BANKING],
        ['healthaccount_hsa', DISPLAY_TYPES.BANKING],
        ['healthaccount_other', DISPLAY_TYPES.BANKING],
        ['insurance_annuity', DISPLAY_TYPES.INSURANCE],
        ['insurance_lifeinsurance', DISPLAY_TYPES.INSURANCE],
        ['insurance_other', DISPLAY_TYPES.INSURANCE],
        ['investment_401a', DISPLAY_TYPES.INVESTMENTS],
        ['investment_401k', DISPLAY_TYPES.INVESTMENTS],
        ['investment_403b', DISPLAY_TYPES.INVESTMENTS],
        ['investment_457b', DISPLAY_TYPES.INVESTMENTS],
        ['investment_529', DISPLAY_TYPES.INVESTMENTS],
        ['investment_brokerage', DISPLAY_TYPES.INVESTMENTS],
        ['investment_cashmanagement', DISPLAY_TYPES.INVESTMENTS],
        ['investment_coverdell', DISPLAY_TYPES.INVESTMENTS],
        ['investment_ira', DISPLAY_TYPES.INVESTMENTS],
        ['investment_keogh', DISPLAY_TYPES.INVESTMENTS],
        ['investment_mutualfund', DISPLAY_TYPES.INVESTMENTS],
        ['investment_other', DISPLAY_TYPES.INVESTMENTS],
        ['investment_pension', DISPLAY_TYPES.INVESTMENTS],
        ['investment_prepaidtuition', DISPLAY_TYPES.INVESTMENTS],
        ['investment_profitshare', DISPLAY_TYPES.CORPORATE_OWNERSHIP],
        ['investment_retirement', DISPLAY_TYPES.INVESTMENTS],
        ['investment_rothira', DISPLAY_TYPES.INVESTMENTS],
        ['investment_sarsep', DISPLAY_TYPES.INVESTMENTS],
        ['investment_savingsbond', DISPLAY_TYPES.INVESTMENTS],
        ['investment_sepira', DISPLAY_TYPES.INVESTMENTS],
        ['investment_treasurybond', DISPLAY_TYPES.INVESTMENTS],
        ['investment_trust', DISPLAY_TYPES.INVESTMENTS],
        ['investment_ugma', DISPLAY_TYPES.INVESTMENTS],
        ['investment_utma', DISPLAY_TYPES.INVESTMENTS],
        ['loan_auto', DISPLAY_TYPES.LOANS],
        ['loan_bank', DISPLAY_TYPES.LOANS],
        ['loan_boat', DISPLAY_TYPES.LOANS],
        ['loan_homeequity', DISPLAY_TYPES.LOANS],
        ['loan_mortgage', DISPLAY_TYPES.MORTGAGE],
        ['loan_other', DISPLAY_TYPES.LOANS],
        ['loan_personal', DISPLAY_TYPES.LOANS],
        ['loan_student', DISPLAY_TYPES.LOANS],
        ['other', DISPLAY_TYPES.INVESTMENTS],
        ['unknown', DISPLAY_TYPES.INVESTMENTS]
    ]);

    const formattedAccountType = accountType.toLowerCase();
    const returnValue = accountTypeMap.get(formattedAccountType);

    return returnValue || '';
}

/**
 * @function getCategoryMapping
 * @description Maps Net Worth Statement category properties to a display type
 * @param {string} category The NWS category value
 * @returns {string} The display type
 */
export function getCategoryMapping(category) {
    const categoryMap = new Map([
        ['bank account', DISPLAY_TYPES.BANKING],
        ['boat loan', DISPLAY_TYPES.LOANS],
        ['business loans', DISPLAY_TYPES.LOANS],
        ['car loan', DISPLAY_TYPES.LOANS],
        ['college saving plans', DISPLAY_TYPES.INVESTMENTS],
        ['college savings plan', DISPLAY_TYPES.INVESTMENTS],
        ['contingent liabilities', DISPLAY_TYPES.LOANS],
        ['credit card debt', DISPLAY_TYPES.CREDIT_CARDS],
        ['home equity line of credit', DISPLAY_TYPES.LOANS],
        ['investment assets', DISPLAY_TYPES.INVESTMENTS],
        ['margin debt', DISPLAY_TYPES.LOANS],
        ['mortgage', DISPLAY_TYPES.MORTGAGE],
        ['muni bonds', DISPLAY_TYPES.INVESTMENTS],
        ['other tax-deferred investments', DISPLAY_TYPES.INVESTMENTS],
        ['personal line of credit', DISPLAY_TYPES.LOANS],
        ['personal note', DISPLAY_TYPES.LOANS],
        ['personal use', DISPLAY_TYPES.PROPERTY],
        ['retirement assets', DISPLAY_TYPES.INVESTMENTS],
        ['student loan', DISPLAY_TYPES.LOANS],
        ['us savings bonds', DISPLAY_TYPES.INVESTMENTS],
        ['unknown', DISPLAY_TYPES.INVESTMENTS]
    ]);

    const formattedCategory = category.toLowerCase();
    const returnValue = categoryMap.get(formattedCategory);

    return returnValue || '';
}

/**
 * @function getSubTypeMapping
 * @description Maps a BAA sub-type to a user-friendly string
 * @param {string} subType The BAA sub-type value
 * @returns {string} The mapped value
 * @todo These will need to be updated for localization
 */
export function getSubTypeMapping(subType) {
    const subTypeMap = new Map([
        ['cashmanagement', 'Cash Management'],
        ['cd', 'CD'],
        ['creditline', 'Credit Line'],
        ['moneymarket', 'Money Market'],
        ['creditcard', 'Credit Card Debt'],
        ['fsa', 'FSA'],
        ['hra', 'HRA'],
        ['hsa', 'HSA'],
        ['lifeinsurance', 'Life Insurance'],
        ['ira', 'IRA'],
        ['mutualfund', 'Mutual Fund'],
        ['prepaidtuition', 'Pre-paid Tuition'],
        ['profitshare', 'Profile Share'],
        ['rothira', 'Roth IRA'],
        ['sarsep', 'SARSEP'],
        ['savingsbond', 'Savings Bond'],
        ['sepira', 'SEP IRA'],
        ['treasurybond', 'Treasury Bond'],
        ['ugma', 'UGMA'],
        ['utma', 'UTMA'],
        ['loan_homeequity', 'Home Equity']
    ]);

    const formattedSubType = subType ? subType.toLowerCase() : 'unknown';
    const returnValue = subTypeMap.get(formattedSubType);

    return returnValue;
}

/**
 * @function getTypeMapping
 * @description Maps a Net Worth Statement `type` property to a display type
 * @param {string} type The NWS type value
 * @returns {string} The display type
 */
export function getTypeMapping(type) {
    const typeMap = new Map([
        ['401(a)', DISPLAY_TYPES.INVESTMENTS],
        ['401(k)', DISPLAY_TYPES.INVESTMENTS],
        ['403(b)', DISPLAY_TYPES.INVESTMENTS],
        ['457 plan', DISPLAY_TYPES.INVESTMENTS],
        ['529', DISPLAY_TYPES.INVESTMENTS],
        ['automobile', DISPLAY_TYPES.PROPERTY],
        ['beneficiary ira', DISPLAY_TYPES.INVESTMENTS],
        ['cash value variable life', DISPLAY_TYPES.INVESTMENTS],
        ['cd', DISPLAY_TYPES.BANKING],
        ['checking', DISPLAY_TYPES.BANKING],
        ['collectibles', DISPLAY_TYPES.PROPERTY],
        ['coverdell', DISPLAY_TYPES.INVESTMENTS],
        ['decedent ria', DISPLAY_TYPES.INVESTMENTS],
        ['deferred compensation plan', DISPLAY_TYPES.INVESTMENTS],
        ['defined benefit plan', DISPLAY_TYPES.PENSION_BENEFITS],
        ['excess benefit plans', DISPLAY_TYPES.INVESTMENTS],
        ['furniture', DISPLAY_TYPES.PROPERTY],
        ['investment assets', DISPLAY_TYPES.INVESTMENTS],
        ['jewelry', DISPLAY_TYPES.PROPERTY],
        ['keogh', DISPLAY_TYPES.INVESTMENTS],
        ['money market', DISPLAY_TYPES.BANKING],
        ['money purchase pension plan', DISPLAY_TYPES.PENSION_BENEFITS],
        ['non-custodian retirement', DISPLAY_TYPES.INVESTMENTS],
        ['other', DISPLAY_TYPES.PROPERTY],
        ['ownership in private company', DISPLAY_TYPES.CORPORATE_OWNERSHIP],
        ['partnership', DISPLAY_TYPES.CORPORATE_OWNERSHIP],
        ['passive ownership', DISPLAY_TYPES.CORPORATE_OWNERSHIP],
        ['primary residence', DISPLAY_TYPES.PROPERTY],
        ['primary residence', DISPLAY_TYPES.PROPERTY],
        ['profit sharing', DISPLAY_TYPES.PENSION_BENEFITS],
        ['real estate', DISPLAY_TYPES.PROPERTY],
        ['roth convertible ira', DISPLAY_TYPES.INVESTMENTS],
        ['roth ira', DISPLAY_TYPES.INVESTMENTS],
        ['salary reduction plan', DISPLAY_TYPES.INVESTMENTS],
        ['sar-sep ira', DISPLAY_TYPES.INVESTMENTS],
        ['savings', DISPLAY_TYPES.BANKING],
        ['sep ira', DISPLAY_TYPES.INVESTMENTS],
        ['simple 401(k)', DISPLAY_TYPES.INVESTMENTS],
        ['simple ira', DISPLAY_TYPES.INVESTMENTS],
        ['sole propreitorship', DISPLAY_TYPES.CORPORATE_OWNERSHIP],
        ['stock bonus plan', DISPLAY_TYPES.PENSION_BENEFITS],
        ['supplemental executive retirement plans', DISPLAY_TYPES.PENSION_BENEFITS],
        ['supplemental plans', DISPLAY_TYPES.INVESTMENTS],
        ['taxable bonds', DISPLAY_TYPES.INVESTMENTS],
        ['target benefit', DISPLAY_TYPES.PENSION_BENEFITS],
        ['top-hat plans', DISPLAY_TYPES.PENSION_BENEFITS],
        ['traditional ira', DISPLAY_TYPES.INVESTMENTS],
        ['traditional rollover ira', DISPLAY_TYPES.INVESTMENTS],
        ['vacation home', DISPLAY_TYPES.PROPERTY],
        ['variable annuity', DISPLAY_TYPES.INVESTMENTS],
        ['unknown', DISPLAY_TYPES.INVESTMENTS]
    ]);

    const formattedType = type.toLowerCase();
    const returnValue = typeMap.get(formattedType);

    return returnValue || '';
}

/**
 * @function getAccountType
 * @description Determines which string can be used as an account type for a merged object
 * @param {string} accountType The BAA accountType property value
 * @param {string} bits The dot-notated path where the account was found in the JSON response
 * @param {string} category The NWS category value
 * @param {string} type The NWS type property
 */
export function getAccountType({
    accountType = '',
    // eslint-disable-next-line no-unused-vars
    bits = '',
    category = '',
    type = ''
}) {
    if (isValidString(type)) {
        return stringToTitleCase(type);
    }

    if (isValidString(category)) {
        return stringToTitleCase(category);
    }

    if (isValidString(accountType)) {
        const accountSubType = accountType.split('_')[1];
        const mappedSubType = getSubTypeMapping(accountSubType);

        if (isValidString(mappedSubType)) {
            return mappedSubType;
        }

        if (isValidString(accountSubType)) {
            return stringToTitleCase(accountSubType);
        }

        return stringToTitleCase(accountType);
    }

    return DISPLAY_TYPES.UNKNOWN;
}

/**
 * @function getDisplayType
 * @description Determines a display type for an account based on available category/type values
 * @param {string} accountType The BAA accountType property value
 * @param {string} bits The dot-notated path where the account was found in the JSON response
 * @param {string} category The NWS category value
 * @param {string} type The NWS type property
 */
export function getDisplayType({
    accountType,
    bits,
    category,
    type
}) {
    let returnValue = '';

    if (isValidString(accountType)) {
        returnValue = getAccountTypeMapping(accountType);
    }

    if (isValidString(type)) {
        returnValue = getTypeMapping(type);
    }

    if (!isValidString(returnValue) && isValidString(category)) {
        returnValue = getCategoryMapping(category);
    }

    // NWS accounts live in a deeply-nested hierarchy, traversed using dot-notated "bits"
    if (!isValidString(returnValue) && isValidString(bits)) {
        const formattedBits = bits.toLowerCase();

        if (formattedBits.indexOf('loans') > -1) {
            returnValue = DISPLAY_TYPES.LOANS;
        } else if (formattedBits.indexOf('taxfreeassets') > -1) {
            returnValue = DISPLAY_TYPES.BANKING;
        } else if (formattedBits.indexOf('insurance') > -1) {
            returnValue = DISPLAY_TYPES.INSURANCE;
        }
    }

    return returnValue;
}
