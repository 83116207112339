import createDOMPurify from 'dompurify';

export const SANITIZER = createDOMPurify(window);

export const SANITIZER_DEFAULTS = {
    ALLOWED_TAGS: [
        'a',
        'b',
        'del',
        'em',
        'i',
        'ins',
        'mark',
        'p',
        'small',
        'span',
        'strong',
        'sub',
        'sup'
    ]
};

export function hasMarkup(str) {
    return typeof str === 'string'
        && ['', '&nbsp;', '<p></p>'].indexOf(str.trim()) === -1;
}

export function sanitizeHtml(html, options = {}) {
    return SANITIZER.sanitize(html, {
        ...SANITIZER_DEFAULTS,
        ...options
    });
}
