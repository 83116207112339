<template>
    <div class="pfm-account-list__accounts">
        <div
            v-for="displayType in allDisplayTypes"
            class="pfm-account-list__display-type"
            :data-ordinal="displayType.ordinal"
            :key="getUniqueID(displayType.name, '-container')">
            <h3 class="pfm-account-list__display-type-title"
                :aria-labelledby="`accountDisplayType${removeSpaces(displayType.name)}`"
                :aria-describedby="`accountDisplayType${removeSpaces(displayType.name)}`">
                {{displayType.name}}
                <mds-button
                    v-if="enableTooltip(displayType.name)"
                    @click="showTooltip = !showTooltip"
                    icon="info-circle"
                    :id="`${mwcId}.investmentTooltip`"
                    size="small"
                    type="button"
                    variation="icon-only" />
                <mds-tooltip
                    v-if="enableTooltip(displayType.name)"
                    v-model="showTooltip"
                    :auto-position="true"
                    headingLevel="4"
                    position="right-bottom"
                    :triggered-by="`${mwcId}.investmentTooltip`"
                    variation="prompt">{{labels.investmentTooltipText}}</mds-tooltip>
            </h3>
            <ul>
                <li
                    v-for="account in getAccountsByDisplayType(displayType.name)"
                    class="pfm-account-list__account"
                    :data-account-baa-id="account.baaAccountId"
                    :data-source="account.source"
                    :key="getUniqueID(account.key, '-account')">
                    <div class="pfm-account-list__account-content">
                        <div
                            v-html="account.title"
                            class="pfm-account-list__account-title"></div>
                        <div class="pfm-account-list__account-sub-title">
                            <div
                                v-html="account.institutionName"
                                :aria-description="labels.ariaInstitutionName"
                                class="pfm-account-list__account-institution"></div>
                            <div
                                v-html="account.displayAccountNumber"
                                :aria-description="labels.ariaAccountFourDigit"
                                class="pfm-account-list__account-number"></div>
                        </div>
                        <div
                            v-if="accountTag(account) && isCashflowEnabled"
                            class="pfm-account-list__unlinked-tag">
                            <mds-tag>{{accountTag(account)}}</mds-tag>
                        </div>
                    </div>
                    <div class="pfm-account-list__account-metadata">
                        <p class="pfm-account-list__account-value">
                            <span
                                v-html="account.currencyValue"
                                :aria-description="labels.ariaAccountBalance"
                                class="pfm-account-list__unbreakable"></span>
                        </p>
                        <p class="pfm-account-list__account-updated pfm-account-list__unbreakable">
                            <span
                                v-if="showErrorIcon(account)"
                                :aria-description="labels.ariaAccountUpdatedAt">
                                {{account.displayLastUpdated}}
                                <mds-icon-exclaim-circle-fill
                                    :class="errorIconClass" />
                            </span>
                            <span
                                v-else-if="showPendingIcon(account)"
                                class="pfm-account-list__unbreakable">
                                {{labels.pending}}
                                <mds-icon-exclaim-circle-fill
                                    :class="warningIconClass" />
                            </span>
                            <span
                                v-else-if="showSyncingIcon(account)"
                                :aria-description="labels.ariaAccountSyncing">
                                {{labels.syncing}}
                                <pfm-loader
                                    aria-hidden="true"
                                    class="pfm-account-list__account-status-spinner"
                                    container-class="pfm-account-list__account-status-spinner-container"
                                    size="small" />
                            </span>
                            <span
                                v-else
                                class="pfm-account-list__unbreakable"
                                :aria-description="labels.ariaAccountUpdatedAt">
                                {{account.displayLastUpdated}}
                            </span>
                        </p>
                    </div>
                    <div class="pfm-account-list__account-action">
                        <mds-button
                            aria-labelledby="ariaAccountDetailsBtn"
                            aria-describedby="ariaAccountDetailsBtn"
                            @click="emitSetAccountOfInterest(account)"
                            icon="caret-right"
                            type="button"
                            variation="icon-only">
                            <span class="visually-hidden">View account detail</span>
                        </mds-button>
                    </div>
                </li>
            </ul>
            <p :id="`accountDisplayType${removeSpaces(displayType.name)}`" hidden>
                {{`${labels.ariaAccountDisplayType} ${displayType.name} ${labels.accounts}`}}</p>
        </div>
        <p id="ariaAccountDetailsBtn" hidden>{{labels.ariaAccountDetailsBtn}}</p>
    </div>
</template>

<script>
import MdsTooltip from '@mds/tooltip';
import { MdsButton } from '@mds/button';
import { MdsTag } from '@mds/tag';
import { DISPLAY_TYPES } from 'pfm-shared/helpers/display-type';
import PfmSharedMixin from 'pfm-shared/mixins/pfm-shared';
import { compareAccounts } from 'pfm-shared/helpers/account';
import AccountListMixin from '../mixins/account-list';

export default {
    components: {
        MdsTag,
        MdsTooltip,
        MdsButton
    },
    computed: {
        investmentsName() {
            return DISPLAY_TYPES.INVESTMENTS;
        }
    },
    data() {
        return {
            showTooltip: false
        };
    },
    methods: {
        accountTag(account) {
            const { labels } = this;
            const { source } = account;
            const accountWasDeleted = this.accountWasDeleted(account);
            const accountWasUnlinked = this.accountWasUnlinked(account);

            if (accountWasDeleted
            || (accountWasUnlinked && account.displayTypeName === DISPLAY_TYPES.INVESTMENTS)) {
                return labels.deletedAccount;
            } if (source === 'unlinked' || accountWasUnlinked) {
                return labels.unlinked;
            }

            return '';
        },
        accountWasDeleted(account) {
            return compareAccounts(account, this.deletedNetWorthAccount);
        },
        accountWasUnlinked(account) {
            return compareAccounts(account, this.unlinkedBaaAccount);
        },
        enableTooltip(displayTypeName) {
            return displayTypeName === this.investmentsName
                && this.showInvestmentTooltip === true;
        },
        showErrorIcon(account) {
            return account && account.hasError === true;
        },
        showPendingIcon(account) {
            return account
                && account.isPending === true
                && account.isInvestment === true
                && account.source === 'aggregation';
        },
        showSyncingIcon(account) {
            const { isSyncInProgress } = this;

            return isSyncInProgress === true
                && account
                && account.isInvestment === false
                && (account.source === 'aggregation' || account.source === 'combined');
        },
        removeSpaces(value) {
            return value.split(' ').join('');
        }
    },
    mixins: [PfmSharedMixin, AccountListMixin],
    name: 'pfm-account-list-simple-linked-accounts',
    props: {
        allAccounts: {
            default: () => ([]),
            type: Array
        },
        allDisplayTypes: {
            default: () => ([]),
            type: Array
        },
        deletedNetWorthAccount: {
            type: Object
        },
        errorIconClass: {
            default: '',
            type: String
        },
        isSyncInProgress: {
            default: false,
            type: Boolean
        },
        labels: {
            default: () => ({}),
            type: Object
        },
        showInvestmentTooltip: {
            default: false,
            type: Boolean
        },
        unlinkedBaaAccount: {
            type: Object
        },
        useBaa: {
            default: true,
            type: Boolean
        },
        warningIconClass: {
            default: '',
            type: String
        },
        isCashflowEnabled: {
            default: true,
            type: Boolean
        }
    }
};
</script>

<style lang="scss" module>
:global {
    .visually-hidden:not(:focus):not(:active) {
        clip: rect(0 0 0 0);
        clip-path: inset(100%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    .pfm-account-list__accounts ul {
        padding: 0;
        margin: 0;
    }
}
</style>
