<template>
    <div class="cwp-notification">
        <div
            v-for="(items, index) in itemList"
            :key="index"
            :class="{
                'mds-notification--bottom-right': index === 'bottom',
                'mds-notification--top-center mds-notification--below-masthead-tall': index === 'top',
                'mds-notification--inline': index === 'none',
                'mds-notification mds-notification--active': true
            }"
        >
            <div
                v-for="(item, index) in items"
                :key="index"
                :class="{
                    'mds-notification__item--dismissible': item.isDismissible,
                    'mds-notification__item--tinted': item.isTinted,
                    'mds-notification__item--error': item.type === 'error',
                    'mds-notification__item--success': item.type === 'success',
                    'mds-notification__item--warning': item.type === 'warning',
                    'mds-notification__item mds-notification__item--visible': true
                }"
                :role="item.buttonText ? 'alertdialog': 'alert'"
            >
                <div class="mds-notification__status">
                    <info-circle-icon
                        v-if="item.type === 'info'"
                        class="mds-icon mds-notification__icon"
                        title="Information"/>
                    <check-icon
                        v-if="item.type === 'success'"
                        class="mds-icon mds-notification__icon"
                        title="Success"/>
                    <alert-icon
                        v-if="item.type === 'warning'"
                        class="mds-icon mds-notification__icon"
                        title="Warning"/>
                    <alert-icon
                        v-if="item.type === 'error'"
                        class="mds-icon mds-notification__icon"
                        title="Error"/>
                </div>
                <div class="mds-notification__body">
                    <div class="mds-notification__content">
                        <p
                            v-html="item.message"
                            class="mds-notification__message"></p>
                        <ul v-if="item.list" class="mds-notification__list">
                            <li
                                v-for="(listItem, index) in item.list"
                                :key="index"
                                class="mds-notification__list-item">{{listItem.name}}</li>
                        </ul>
                        <button
                            :disabled="isIE || readMode"
                            v-if="item.buttonText"
                            class="mds-button mds-button--small mds-button--secondary"
                            type="button"
                            @click="onClickBtn(item)"
                        >
                            {{item.buttonText}}
                        </button>
                    </div>
                </div>
                <button class="mds-button mds-notification__dismiss-button mds-button--small mds-button--icon-only"
                        type="button" v-if="item.isDismissible"
                        @click="onClickClose(item)">
                    <remove-icon
                        class="mds-icon mds-button__icon mds-button__icon--right"
                        title="close"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable import/no-duplicates */
import InfoCircleIcon from 'morningstar-design-system/dist/mds.svg?info-circle';
import CheckIcon from 'morningstar-design-system/dist/mds.svg?check';
import AlertIcon from 'morningstar-design-system/dist/mds.svg?alert';
import RemoveIcon from 'morningstar-design-system/dist/mds.svg?remove--s';
/* eslint-enable import/no-duplicates */

export default {
    name: 'notification',
    components: {
        InfoCircleIcon,
        RemoveIcon,
        CheckIcon,
        AlertIcon
    },
    props: {
        contentList: {
            type: Array,
            default: () => ([])
        },
        readMode: {
            default: false,
            type: Boolean
        }
    },
    data() {
        return {
            autoCloseItems: {},
            isIE: window.isIE
        };
    },
    beforeDestroy() {
        Object.keys(this.autoCloseItems).forEach(element => {
            clearTimeout(this.autoCloseItems[element.id]);
        });
        this.autoCloseItems = null;
    },
    computed: {
        itemList() {
            this.autoClose(this.contentList);
            return (this.contentList || []).reduce((res, item) => {
                if (res[item.position]) {
                    res[item.position].push(item);
                }
                return {
                    [item.position]: [item],
                    ...res
                };
            }, {});
        }
    },
    methods: {
        onClickClose(item) {
            if (this.autoCloseItems && this.autoCloseItems[item.id]) {
                clearTimeout(this.autoCloseItems[item.id]);
                delete this.autoCloseItems[item.id];
            }
            this.$emit('notification-dismiss', item);
        },
        onClickBtn(item) {
            this.$emit('notification-secondary-action', item);
        },
        autoClose(value) {
            value.forEach(element => {
                if (element.autoHide) {
                    if (!this.autoCloseItems[element.id]) {
                        this.autoCloseItems[element.id] = setTimeout(() => {
                            this.onClickClose(element);
                        }, element.timeout);
                    }
                }
            });
        }
    }
};
</script>

<style scoped lang="scss">
    @import '../styles/variables';

    .cwp-notification {
        & .mds-notification {
            z-index: $mds-z-index-modal-content - 1;

            &.mds-notification--inline{
                position: relative;
                top: auto;
            }
            &.mds-notification--bottom-right{
                top: auto;
            }
            // TODO: Need to solve the flickering issue
            & .mds-notification__item--visible {
                animation: none;
            }
        }
    }
</style>
