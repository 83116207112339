<template>
    <div class="cwp-notification-wrapper" v-if="!isNotificationAlreadyRendered">
        <notification
            :content-list="contentList"
            :read-mode="readMode"
            @notification-secondary-action="actionCall"
            @notification-dismiss="deleteItem"
        />
    </div>
</template>

<script>
import Notification from './Notification';

export default {
    name: 'cwp-notification-wrapper',
    components: {
        Notification
    },
    props: {
        readMode: {
            default: false,
            type: Boolean
        }
    },
    /* istanbul ignore next */
    created() {
        this.isNotificationAlreadyRendered = document.querySelector('.cwp-notification-wrapper');
    },
    data() {
        return {
            contentList: [],
            stackContents: {},
            isNotificationAlreadyRendered: true
        };
    },
    mounted() {
        this.addItem();
    },
    /* istanbul ignore next */
    beforeDestroy() {
        document.removeEventListener('cwp-notification-content', this.createList);
    },
    methods: {
        actionCall(data) {
            document.dispatchEvent(new CustomEvent('cwp-notification-secondary-action', {
                detail: data
            }));
        },
        deleteItem(data) {
            document.dispatchEvent(new CustomEvent('cwp-notification-dismiss', {
                detail: data
            }));
        },
        createList(event) {
            Object.keys(event.detail).forEach(key => {
                this.stackContents[key] = event.detail[key];
            });

            const contentList = [];
            Object.keys(this.stackContents).forEach(key => {
                contentList.unshift(...this.stackContents[key]);
            });
            this.contentList = contentList;
        },
        addItem() {
            document.addEventListener('cwp-notification-content', this.createList);
        }
    }
};
</script>
