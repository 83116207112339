export default {
    'displayTypes.banking': 'Banking',
    'displayTypes.corporateOwnership': 'Corporate Ownership',
    'displayTypes.creditCards': 'Credit Cards',
    'displayTypes.insurance': 'Insurance',
    'displayTypes.investments': 'Investments',
    'displayTypes.loans': 'Loans',
    'displayTypes.mortgage': 'Mortgage',
    'displayTypes.otherAccounts': 'Other Accounts',
    'displayTypes.pensionBenefits': 'Pension Benefits',
    'displayTypes.property': 'Property',
    'displayTypes.unknown': 'Unknown'
};
