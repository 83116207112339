import isValid from 'date-fns/is_valid';
import differenceInDays from 'date-fns/difference_in_days';
import differenceInHours from 'date-fns/difference_in_hours';
import differenceInMinutes from 'date-fns/difference_in_minutes';
import differenceInYears from 'date-fns/difference_in_years';
import differenceInMonths from 'date-fns/difference_in_months';
import isToday from 'date-fns/is_today';
import format from 'date-fns/format';
import { strDateToUTC } from './formatter';

export { default as addDay } from 'date-fns/add_days';
export { default as addMonths } from 'date-fns/add_months';
export { default as getDay } from 'date-fns/get_day';
export { default as getMonth } from 'date-fns/get_month';
export { default as lastDayOfMonth } from 'date-fns/last_day_of_month';
export { default as startOfMonth } from 'date-fns/start_of_month';
export { default as startOfDay } from 'date-fns/start_of_day';
export { default as startOfYear } from 'date-fns/start_of_year';
export { default as subDays } from 'date-fns/sub_days';
export { default as subHours } from 'date-fns/sub_hours';
export { default as subMinutes } from 'date-fns/sub_minutes';
export { default as subMonths } from 'date-fns/sub_months';
export { default as subYears } from 'date-fns/sub_years';

export {
    differenceInDays,
    differenceInHours,
    differenceInMinutes,
    differenceInYears,
    differenceInMonths,
    format,
    isToday
};

// @TODO This needs to be updated to support il8n
export function formatLastUpdatedDate(dateString, currentDate) {
    const utcDateString = strDateToUTC(dateString) || dateString;
    const dateObject = new Date(utcDateString);

    if (!isValid(dateObject)) {
        return '';
    }

    const today = currentDate || new Date();
    const daysDifference = differenceInDays(today, dateObject);
    const hoursDifference = differenceInHours(today, dateObject);
    const minutesDifference = differenceInMinutes(today, dateObject);

    if (minutesDifference < 1) {
        return 'just now';
    }

    if (minutesDifference > 0 && minutesDifference < 60) {
        return `${minutesDifference} minute${minutesDifference > 1 ? 's' : ''} ago`;
    }

    if (hoursDifference > 0 && hoursDifference < 24) {
        return `${hoursDifference} hour${hoursDifference > 1 ? 's' : ''} ago`;
    }

    if (daysDifference > 0 && daysDifference < 7) {
        return `${daysDifference} day${daysDifference > 1 ? 's' : ''} ago`;
    }

    return `as of ${format(dateObject, 'MMM D')}`;
}
