/* eslint-disable no-restricted-globals */
import isValid from 'date-fns/is_valid';
import format from 'date-fns/format';

const langCode = {
    usd: 'en-us',
    aud: 'en-au'
};

export function formatNumber(amount, decimalPlaces = 0) {
    if (Number.isNaN(amount)) {
        return '\u2014';
    }

    return new Intl.NumberFormat(langCode.usd, {
        style: 'decimal',
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces
    }).format(amount).replace(/-/g, '\u2212');
}

export function formatCurrency(amount, currencyCode = 'USD', decimalPlaces = 0) {
    if (Number.isNaN(amount)) {
        return '\u2014';
    }

    return new Intl.NumberFormat(langCode[currencyCode.toLowerCase()], {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: currencyCode === 'AUD' ? 'narrowSymbol' : 'symbol',
        maximumFractionDigits: decimalPlaces,
        minimumFractionDigits: decimalPlaces
    }).format(amount).replace(/-/g, '\u2212');
}

export function formatPercent(number, decimalPlaces = 2) {
    if (Number.isNaN(number)) {
        return '\u2014';
    }
    return new Intl.NumberFormat({ localeMatcher: 'lookup' }, {
        style: 'percent',
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces
    }).format(number / 100).replace(/-/g, '\u2212');
}

export function formatReturns(amount) {
    if (Number.isNaN(amount) || amount === 'NaN') {
        return '\u2014';
    }
    return Number.parseFloat(amount).toFixed(2).replace(/-/g, '\u2212');
}

/** Depending on language code format will change or pass formatString for custom date
 *
 * @param {Date|String} date date object or date string
 * @param {Boolean} intl true or false - to support date intl
 * @param {String} code Currency code | USD, AUD
 * @param {object} option Intl.DateTimeFormat config
 * @param {String} formatString date custom string - 'MM/DD/YYYY'
 */
export function formatDate(
    date,
    intl = false,
    code = 'USD',
    option = { month: '2-digit', day: '2-digit', year: 'numeric' },
    formatString = 'MM/DD/YYYY'
) {
    if (!date) {
        return '\u2014';
    }

    /**
     * check for a valid date, including support for strings and numbers
     *
     * if not a proper Date object, force cast it into a Date
     */
    const targetDate = (Number.isNaN(date) || typeof date !== 'object') ? new Date(date) : date;
    if (isValid(targetDate)) {
        if (intl) {
            const locale = langCode[code.toLowerCase()];
            return new Intl.DateTimeFormat(locale, option).format(targetDate);
        }
        return format(targetDate, formatString);
    }
    return '\u2014';
}

export function strDateToUTC(strDate) {
    if (typeof strDate !== 'string' || !/^\d{4}-\d{2}-\d{2}$/.test(strDate)) {
        return undefined;
    }

    return new Date(`${strDate}T00:00:00`);
}

export function strToTitleCase(str) {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCaseStr = str.toLowerCase();
    const stringParts = lowerCaseStr.split(' ');
    const stringPartsCount = stringParts.length;

    for (let i = 0; i < stringPartsCount; i += 1) {
        stringParts[i] = stringParts[i].charAt(0).toUpperCase() + stringParts[i].slice(1);
    }

    return stringParts.join(' ');
}

export function decimalAdjustment(num, decimalPlaces = 2) {
    // Number.isNaN('NaN') === false
    // convert to Number object first
    const parsed = Number(num);
    if (Number.isNaN(parsed)) {
        return '\u2014';
    }

    return parsed.toFixed(decimalPlaces);
}
