<template>
    <div class="pfm-loader" :class="wrapperClass">
        <div class="pfm-loader__container" :class="containerClass">
            <mds-loader :size="size" />
        </div>
    </div>
</template>

<script>
import MdsLoader from '@mds/loader';

export default {
    computed: {
        wrapperClass() {
            const { isFullPage } = this;
            const wrapperClasses = [];

            if (isFullPage === true) {
                wrapperClasses.push('pfm-loader--full-page');
            }

            return wrapperClasses.join(' ');
        }
    },
    components: {
        MdsLoader
    },
    name: 'pfm-loader',
    props: {
        ariaValueText: {
            type: String
        },
        containerClass: {
            type: String
        },
        isFullPage: {
            default: false,
            type: Boolean
        },
        size: {
            default: 'medium',
            type: String,
            validator(value) {
                return ['large', 'medium', 'small'].indexOf(value) > -1;
            }
        }
    }
};
</script>

<style lang="scss" module>
@import '~cwp-dev-shared/styles/variables';

:global {
    .pfm-loader {
        &__container {
            position: relative;
            width: 100%;
        }

        &--full-page {
            align-items: center;
            flex-direction: column;
            display: flex;
            height: 25vw;
            justify-content: center;
            padding: $mds-space-inset-2-x;
            width: 100%;
        }
    }
}
</style>
