<template>
    <div class="pfm-account-list__accounts">
        <h2 class="pfm-account-list__header">{{assetText}}</h2>
        <h3 class="pfm-account-list__sub-header">{{assetBalance}}</h3>
        <div
            v-for="displayType in assetTypes"
            class="pfm-account-list__display-type"
            :data-ordinal="displayType.ordinal"
            :key="getUniqueID(displayType.name, '-container')">
            <h4
                v-html="displayType.name"
                :aria-labelledby="`accountDisplayType${removeSpaces(displayType.name)}`"
                :aria-describedby="`accountDisplayType${removeSpaces(displayType.name)}`"
                class="pfm-account-list__display-type-title"></h4>
            <div
                v-for="account in getAccountsByDisplayType(displayType.name)"
                class="pfm-account-list__account"
                :data-account-id="account.id"
                :data-account-type="account.accountType"
                :data-category="account.category"
                :data-source="account.source"
                :data-type="account.type"
                :key="getUniqueID(account.key, '-account')">
                <div class="pfm-account-list__account-content">
                    <h5
                        v-html="account.title"
                        class="pfm-account-list__account-title"></h5>
                    <div class="pfm-account-list__account-sub-title">
                        <div
                            v-html="account.institutionName"
                            :aria-description="labels.ariaInstitutionName"
                            class="pfm-account-list__account-institution"></div>
                        <div
                            v-html="account.displayAccountNumber"
                            :aria-description="labels.ariaAccountFourDigit"
                            class="pfm-account-list__account-number"></div>
                    </div>
                </div>
                <div class="pfm-account-list__account-metadata">
                    <p class="pfm-account-list__account-value">
                        <span
                            v-html="account.currencyValue"
                            :aria-description="labels.ariaAccountBalance"
                            class="pfm-account-list__unbreakable"></span>
                    </p>
                </div>
            </div>
            <p :id="`accountDisplayType${removeSpaces(displayType.name)}`" hidden>
                {{`${labels.ariaAccountDisplayType} ${displayType.name} ${labels.accounts}`}}</p>
        </div>
        <h2 class="pfm-account-list__header">{{debtText}}</h2>
        <h3 class="pfm-account-list__sub-header">{{debtBalance}}</h3>
        <div
            v-for="displayType in debtTypes"
            class="pfm-account-list__display-type"
            :data-ordinal="displayType.ordinal"
            :key="getUniqueID(displayType.name, '-container')">
            <h4
                v-html="displayType.name"
                class="pfm-account-list__display-type-title"></h4>
            <div
                v-for="account in getAccountsByDisplayType(displayType.name)"
                class="pfm-account-list__account"
                :data-account-id="account.id"
                :data-account-type="account.accountType"
                :data-category="account.category"
                :data-source="account.source"
                :data-type="account.type"
                :key="getUniqueID(account.key, '-account')">
                <div class="pfm-account-list__account-content">
                    <h5
                        v-html="account.title"
                        class="pfm-account-list__account-title"></h5>
                    <div class="pfm-account-list__account-sub-title">
                        <div
                            v-html="account.institutionName"
                            class="pfm-account-list__account-institution"></div>
                        <div
                            v-html="account.accountNumber ? '...' + account.accountNumber : ''"
                            class="pfm-account-list__account-number"></div>
                    </div>
                </div>
                <div class="pfm-account-list__account-metadata">
                    <p class="pfm-account-list__account-value">
                        <span
                            v-html="account.currencyValue"
                            class="pfm-account-list__unbreakable"></span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { DISPLAY_TYPES } from 'pfm-shared/helpers/display-type';
import PfmSharedMixin from 'pfm-shared/mixins/pfm-shared';
import AccountListMixin from '../mixins/account-list';

const DEBTS = [
    DISPLAY_TYPES.CREDIT_CARDS,
    DISPLAY_TYPES.LOANS
];

export default {
    data() {
        return {
            assetTypes: this.getAssetTypes(),
            debtTypes: this.getDebtTypes()
        };
    },
    methods: {
        getAssetTypes() {
            const { allDisplayTypes } = this;
            const assetTypes = [];

            allDisplayTypes.forEach(displayType => {
                if (DEBTS.indexOf(displayType.name) === -1) {
                    assetTypes.push(displayType);
                }
            });

            return assetTypes;
        },
        getDebtTypes() {
            const { allDisplayTypes } = this;
            const debtTypes = [];

            allDisplayTypes.forEach(displayType => {
                if (DEBTS.indexOf(displayType.name) > -1) {
                    debtTypes.push(displayType);
                }
            });

            return debtTypes;
        },
        removeSpaces(value) {
            return value.split(' ').join('');
        }
    },
    mixins: [PfmSharedMixin, AccountListMixin],
    name: 'pfm-account-list-vital-signs-accounts',
    props: {
        allAccounts: {
            default: () => ([]),
            type: Array
        },
        allDisplayTypes: {
            default: () => ([]),
            type: Array
        },
        assetBalance: {
            type: Number
        },
        assetText: {
            type: String
        },
        debtBalance: {
            type: Number
        },
        debtText: {
            type: String
        },
        labels: {
            default: () => ({}),
            type: Object
        }
    }
};
</script>

<style scoped>
    .pfm-account-list__sub-header {
        border-bottom: 0;
    }

    .pfm-account-list--no-sub-header .pfm-account-list__header {
        border-bottom: 0;
        padding: 0;
    }

    .pfm-account-list__header{
        margin-top: 16px;
    }
</style>
