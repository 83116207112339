<template>
    <div
        class="pfm-account-list__internal-errors">
        <account-list-alert
            v-for="error in allErrors"
            :action-text="getActionText(error)"
            :data-credential-id="error.id"
            :dismiss="false"
            :display-action-items="error.displayActionItems"
            :key="error.id + '-alert'"
            :message="error.message"
            :read-mode="readMode"
            :mwc-id="`${mwcId}.${error.id + 'Alert'}`"
            @pfm-action-clicked="onActionClicked(error)"
            @pfm-secondary-action-clicked="onSecondaryActionClicked(error)"
            :secondary-action-text="getSecondaryActionText(error)"
            :type="error.type" />
    </div>
</template>

<script>
import PfmSharedMixin from 'pfm-shared/mixins/pfm-shared';
import AccountListMixin from '../mixins/account-list';
import AccountListAlert from './account-list-alert';

export default {
    components: {
        AccountListAlert
    },
    methods: {
        emitDeleteCredential(id) {
            const { mwcId } = this;

            this.$emit('pfm-delete-credential', {
                id,
                mwcId
            });
        },
        emitUpdateCredential(id) {
            const { mwcId } = this;

            this.$emit('pfm-update-credential', {
                id,
                mwcId
            });
        },
        emitDiscoverAccounts(id) {
            const { mwcId } = this;

            this.$emit('pfm-discover-accounts', {
                id,
                mwcId
            });
        },
        getActionText(error) {
            const { labels } = this;
            const { errorButtonLabel, errorType } = error;

            return errorType === 'NOT_USER_RESOLVABLE'
                ? labels.deleteCredentials
                : errorButtonLabel;
        },
        getSecondaryActionText(error) {
            const { labels } = this;
            const { errorType, hasMultipleAccounts } = error;

            return errorType === 'NOT_USER_RESOLVABLE' || hasMultipleAccounts
                ? ''
                : labels.deleteCredentials;
        },
        onActionClicked(error) {
            const { id, errorType } = error;

            if (errorType === 'NOT_USER_RESOLVABLE') {
                this.emitDeleteCredential(id);
            } else if (errorType === 'USER_RESOLVABLE_TYPE_5') {
                this.emitDiscoverAccounts(id);
            } else {
                this.emitUpdateCredential(id);
            }
        },
        onSecondaryActionClicked(error) {
            const { id } = error;

            this.emitDeleteCredential(id);
        }
    },
    mixins: [PfmSharedMixin, AccountListMixin],
    name: 'pfm-account-list-internal-errors',
    props: {
        allErrors: {
            default: () => ([]),
            type: Array
        },
        labels: {
            default: () => ({}),
            type: Object
        },
        readMode: {
            default: false,
            type: Boolean
        }
    }
};
</script>

<style lang="scss" module>
:global {
    .pfm-account-list {
        &__internal-errors {
            width: 100%;
        }
    }
}
</style>
