import COMMON_SETTINGS from 'pfm-shared/config/common-settings';
import labels from './labels';

export default {
    labels,
    accountAggregationConfig: {
        authContext: {
            saml: 'true'
        },
        baaIsAuthenticated: false,
        restBaseUrl: 'https://www.byallaccounts.net',
        route: '/add-account;showProgressStep=false',
        translateFilePath: '',
        uiConfigFile: ''
    },
    components: {},
    mwcId: 'pfmAccountList',
    settings: {
        ...COMMON_SETTINGS,
        accountListView: 'simple',
        dataErrors: [],
        emptyTextAlignment: '',
        netWorthData: {
            assets: {
                totalAssets: 0
            },
            insurance: {
                totalInsurance: 0
            },
            liabilities: {
                totalLiabilities: 0
            }
        },
        notificationStyle: 'external',
        showAccountsBalance: true,
        showInvestmentTooltip: false,
        showLinkAccount: true,
        showPending: true,
        showSupportModal: false,
        useBaa: true,
        readMode: false,
        showEmptyStateIcon: true,
        showAccountTooltip: false,
        isCashflowEnabled: true
    }
};
