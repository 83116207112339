<template>
    <section
        class="pfm-account-list"
        :class="containerClass"
        :data-pfm-component="componentVersion">
        <div class="pfm-account-list__header-wrapper">
            <h2
                v-html="labels.accounts"
                aria-labelledby="ariaAccounts"
                aria-describedby="ariaAccounts"
                class="pfm-account-list__header"
                :data-msat="getMSAT('header')"></h2>
            <span v-if="showAccountTooltip" class="key-datapoints__info-popover">
                <info-popover
                    :size="'200px'"
                    :position="'right-bottom'">
                    <p>{{ labels.accountsTooltip }}</p>
                </info-popover>
            </span>
        </div>
        <div
            v-if="showAccountsBalance"
            v-html="accountsBalance"
            :aria-description="labels.ariaAccountsBalance"
            class="pfm-account-list__sub-header"
            :data-msat="getMSAT('balance')"></div>
        <!-- Loader -->
        <div
            :aria-busy="loadingParsed"
            aria-live="polite">
            <pfm-loader
                v-if="loadingParsed"
                role="alert"
                :aria-label="labels.ariaAccountInfoLoading"
                :is-full-page="true" />
        </div>
        <!-- Internal Error Notifications -->
        <h3
            v-if="showInternalNotifications"
            v-html="labels.errorsHeading"
            aria-labelledby="ariaErrorsHeading"
            aria-describedby="ariaErrorsHeading"
            class="pfm-account-list__display-type-title"></h3>
        <internal-errors
            v-if="showInternalNotifications"
            :all-errors="allErrors"
            :data-msat="getMSAT('internal-errors')"
            :read-mode="readMode"
            :labels="labels"
            :mwc-id="`${mwcId}.internalErrors`"
            @pfm-delete-credential="emitDeleteCredential"
            @pfm-update-credential="emitUpdateCredential"
            @pfm-discover-accounts="emitDiscoverAccounts" />
        <!-- Empty -->
        <div
            v-if="showEmpty && !accountOfInterest"
            class="pfm-account-list__empty"
            :class="[
                emptyTextClass,
                showInternalNotifications ? 'pfm-account-list__has-error' : ''
            ]"
            :data-msat="getMSAT('empty-text', 'container')">
            <div
                v-if="!showInternalNotifications"
                class="pfm-account-list__empty-text">
                <div
                    v-if="emptyHasMarkup"
                    v-html="labels.noLinkedAccountsHTML"
                    class="pfm-account-list__empty-text-html"
                    :aria-description="labels.noLinkedAccountsHTML"
                    :data-msat="getMSAT('empty-text', 'html')"></div>
                <div v-else
                     class="pfm-account-list__empty-container"
                     :data-msat="getMSAT('empty-text', 'label')">
                    <p v-html="noLinkedAccountsLabel"
                       class="pfm-account-list__empty-label"
                       :data-msat="getMSAT('empty-text', 'label')"></p>
                    <div v-if="showEmptyStateIcon" class="pfm-account-list__icon-list">
                        <div class="pfm-account-list__icon">
                            <span v-html="svgIcon('arrow-left-right')"></span>
                        </div>
                        <div class="pfm-account-list__icon">
                            <span v-html="svgIcon('chart-growth-combo')"></span>
                        </div>
                        <div class="pfm-account-list__icon">
                            <span v-html="svgIcon('chart-donut-slice-four')"></span>
                        </div>
                    </div>
                    <p v-html="labels.noLinkedAccountsText"
                       class="pfm-account-list__empty-sub-text"
                       :data-msat="getMSAT('empty-text', 'sub-text')"></p>
                </div>
            </div>
            <mds-button
                v-if="showLinkAccount"
                v-html="labels.linkAccount"
                :disabled="readMode"
                :aria-label="labels.linkAccount"
                size="large"
                class="pfm-account-list__link-account-button"
                @click="emitLinkAccount"
                :data-msat="getMSAT('link-account-button')"
                variation="primary" />
        </div>
        <!-- Account Details -->
        <account-detail
            v-if="!loadingParsed && accountOfInterest"
            :account="accountOfInterest"
            :data-msat="getMSAT('account-details', 'container')"
            :deleted-account="deletedNetWorthAccount"
            :labels="labels"
            :mwc-id="`${mwcId}.accountDetail`"
            @pfm-show-delete-account="emitShowDeleteAccount"
            @pfm-dismiss-account="emitDismissAccount"
            @pfm-show-support-modal="emitShowSupportModal"
            @pfm-show-unlink-account="emitShowUnlinkAccount"
            @pfm-update-credential="emitUpdateCredential"
            @pfm-delete-credential="emitDeleteCredential"
            :show-support-modal="showSupportModal"
            :unlinked-account="unlinkedBaaAccount"
            :use-baa="useBaa"
            :read-mode="readMode"
            :is-cashflow-enabled="isCashflowEnabled"
            :is-pending-request="isPendingRequest" />
        <!-- Has Accounts -->
        <div v-if="showAccounts">
            <!-- Unlinked Accounts -->
            <div
                v-if="hasLinkingInstitutions"
                class="pfm-account-list__unlinked-accounts"
                :data-unlinked-accounts="linkingInstitutions.length"
                :data-msat="getMSAT('unlinked-accounts', 'container')">
                <h3
                    v-html="labels.linking"
                    class="pfm-account-list__unlinked-accounts-title"
                    :data-msat="getMSAT('unlinked-accounts', 'header')"></h3>
                <div
                    v-for="account in linkingInstitutions"
                    class="pfm-account-list__unlinked-account"
                    :data-msat="getMSAT('unlinked-accounts', `account-${account.key}`)"
                    :key="getUniqueID(account.key, '-account')">
                    <div class="pfm-account-list__unlinked-account-loader">
                        <pfm-loader size="small" />
                    </div>
                    <div class="pfm-account-list__unlinked-account-content">
                        <div
                            v-html="account.institutionName"
                            class="pfm-account-list__unlinked-account-title"></div>
                        <p
                            v-text="account.subtitle"
                            class="pfm-account-list__unlinked-account-sub-title"></p>
                    </div>
                </div>
            </div>
            <!-- Linked Accounts -->
            <simple-linked-accounts
                v-if="hasAccounts && accountListView === 'simple'"
                :all-accounts="allAccounts"
                :all-display-types="allDisplayTypes"
                :data-msat="getMSAT('simple-linked-accounts')"
                :deleted-net-worth-account="deletedNetWorthAccount"
                :error-icon-class="errorIconClass"
                :is-sync-in-progress="isSyncInProgress"
                :is-cashflow-enabled="isCashflowEnabled"
                :key="`simple-linked-accounts-${lastDataRefresh}`"
                :labels="labels"
                :mwc-id="`${mwcId}.simpleLinkedAccounts`"
                @pfm-account-of-interest="setAccountOfInterest"
                :show-investment-tooltip="showInvestmentTooltip"
                :unlinked-baa-account="unlinkedBaaAccount"
                :use-baa="useBaa"
                :warning-icon-class="warningIconClass" />
            <vital-signs-accounts
                v-if="hasAccounts && accountListView === 'vital-signs-nw'"
                :all-accounts="allAccounts"
                :all-display-types="allDisplayTypes"
                :asset-balance="displayNetWorthData.assets.totalAssets"
                :asset-text="labels.assetText"
                :data-msat="getMSAT('vital-signs-nw-accounts')"
                :debt-balance="displayNetWorthData.liabilities.totalLiabilities"
                :debt-text="labels.debtText"
                :error-icon-class="errorIconClass"
                :is-asset="true"
                :is-debt="true"
                :is-sync-in-progress="isSyncInProgress"
                :key="`nw-linked-accounts-${lastDataRefresh}`"
                :labels="labels"
                :mwc-id="`${mwcId}.vitalSignsNW`"
                :non-investment-accounts="nonInvestmentAccounts"
                @pfm-account-of-interest="setAccountOfInterest"
                :show-investment-tooltip="showInvestmentTooltip"
                :use-baa="useBaa"
                :warning-icon-class="warningIconClass" />
            <vital-signs-accounts
                v-if="hasAccounts && accountListView === 'vital-signs-mos'"
                :all-accounts="allAccounts"
                :all-display-types="allDisplayTypes"
                :asset-balance="accountsBalance"
                :asset-text="labels.assetText"
                :data-msat="getMSAT('vital-signs-mos-accounts')"
                :error-icon-class="errorIconClass"
                :is-asset="true"
                :is-sync-in-progress="isSyncInProgress"
                :key="`mos-linked-accounts-${lastDataRefresh}`"
                :labels="labels"
                :mwc-id="`${mwcId}.vitalSignsMOS`"
                :non-investment-accounts="nonInvestmentAccounts"
                @pfm-account-of-interest="setAccountOfInterest"
                :show-investment-tooltip="showInvestmentTooltip"
                :use-baa="useBaa"
                :warning-icon-class="warningIconClass" />
        </div>
        <div v-if="unRegisteredFIs.length && !accountOfInterest"
             class="pfm-account-list__accounts">
            <h3 v-html="labels.requestedFI"
                aria-labelledby="ariaRequestedFI"
                aria-describedby="ariaRequestedFI"
                class="pfm-account-list__display-type-title"></h3>
            <div v-for="institution in unRegisteredFIs"
                 :key="institution.id">
                <div class="pfm-account-list__account">
                    <div class="pfm-account-list__account-content">
                        <div
                            v-html="institution.title"
                            :aria-labelledby="`institutionNameAccList${institution.title}`"
                            class="pfm-account-list__account-title"></div>
                        <p :id="`institutionNameAccList${institution.title}`" hidden>
                            {{labels.ariaInstitutionName}} {{institution.title}}</p>
                        <div class="pfm-account-list__account-sub-title">
                            <div
                                v-html="institution.displayAccountNumber"
                                :aria-description="labels.ariaAccountFourDigit"
                                class="pfm-account-list__account-number"></div>
                        </div>
                    </div>
                    <div class="pfm-account-list__account-action">
                        <button
                            class="mds-button mds-button--icon-only"
                            @click="setAccountOfInterest(institution, true)"
                            :disabled="readMode"
                            :aria-label="labels.ariaPendingAccountDetailsBtn">
                            <mds-icon-caret-right
                                aria-hidden="true"
                                class="mds-icon mds-icon--s" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <mds-button
            v-if="showLinkAccount && showAccounts"
            v-html="labels.linkAccount"
            class="pfm-account-list__link-account-button"
            @click="emitLinkAccount"
            :data-msat="getMSAT('link-account-button')"
            :disabled="readMode"
            variation="secondary" />
        <!-- External Error Notifications -->
        <baa-notification
            v-if="showExternalNotifications"
            :mwc-id="`${mwcId}.notification`"
            :notification-list="allErrors"
            :read-mode="readMode"
            :not-user-resolvable-message="labels.notUserResolvable"
            @pfm-delete-credential="emitDeleteCredential"
            @pfm-update-credential="emitUpdateCredential"
            @pfm-discover-accounts="emitDiscoverAccounts"
            :reenter-button-text="labels.reenterButtonText" />
        <p id="ariaAccounts" hidden>{{labels.ariaAccounts}}</p>
        <p id="ariaErrorsHeading" hidden>{{labels.ariaNeedsAttention}}}</p>
        <p id="ariaRequestedFI" hidden>{{`${labels.ariaAccountDisplayType} ${labels.requestedFI}`}}</p>
    </section>
</template>

<script>
import BaaNotification from 'cwp-dev-shared/components/BaaNotification';
import { formatLastUpdatedDate } from 'cwp-dev-shared/helpers/date';
import InfoPopover from 'cwp-dev-shared/components/InfoPopover';
import { MdsButton } from '@mds/button';
import {
    ASSET_BITS,
    INSURANCE_BITS,
    LIABILITY_BITS,
    ERROR_BUTTON_LABELS,
    credentialHasAccount,
    accountHasType,
    accountIsModified,
    credentialIsComplete,
    formatAccount,
    formatInstitution,
    getCredentialAccounts,
    getCredentialErrorType,
    getCredentialsFromResponse,
    isAccountAggregated,
    mergeAccounts,
    resolveAccounts
} from 'pfm-shared/helpers/account';
import { screenReaderStatus } from 'pfm-shared/helpers/elements';
import icons from 'pfm-shared/images/icons.json';
import {
    DISPLAY_TYPE_ORDER,
    isInvestmentAccountType,
    DISPLAY_TYPES
} from 'pfm-shared/helpers/display-type';
import { hasMarkup, sanitizeHtml } from 'pfm-shared/helpers/html';
import {
    isTruthy,
    isValidArray,
    isValidObject,
    validBoolean
} from 'pfm-shared/helpers/valid';
import PfmUIMixin from 'pfm-shared/mixins/pfm-ui';
import forceNextTick from 'pfm-shared/helpers/vueForceNextTick';
import AccountDetail from './components/account-detail';
import AccountListMixin from './mixins/account-list';
import InternalErrors from './components/internal-errors';
import SimpleLinkedAccounts from './components/simple-linked-accounts';
import VitalSignsAccounts from './components/vital-signs-accounts';
import { mwcComponentName, version } from '../package.json';
import DEFAULT_CONFIG from './_config';

const IN_PROGRESS = 'In Progress';

export default {
    /* istanbul ignore next */
    beforeDestroy() {
        clearInterval(this.timerForDateUpdate);
        this.$emit('pfm-component-destroyed', this.componentProfile);
    },
    components: {
        AccountDetail,
        BaaNotification,
        InternalErrors,
        MdsButton,
        SimpleLinkedAccounts,
        VitalSignsAccounts,
        InfoPopover
    },
    computed: {
        accountListView() {
            const { settings } = this;

            return settings.accountListView;
        },
        showAccountTooltip() {
            const { settings } = this;

            return settings.showAccountTooltip;
        },
        isCashflowEnabled() {
            const { settings } = this;

            return validBoolean(settings.isCashflowEnabled, true);
        },
        accountsBalance() {
            const { allAccounts } = this;
            let accountBalance = 0;

            allAccounts.forEach(account => {
                accountBalance += account.value;
            });

            return this.cleanCurrencyWithMwc(accountBalance);
        },
        allAccounts() {
            if (this.loadingParsed) {
                return [];
            }

            const {
                baaCredentials,
                hasLinkingInstitutions,
                linkingInstitutions,
                showPending,
                useBaa
            } = this;
            const hasCredentials = baaCredentials.length > 0;

            if (hasCredentials) {
                this.updateTimeForAccountDate();
            }

            // Not every `credential` has an `account`
            const accountList = useBaa
                ? getCredentialAccounts(baaCredentials).map(credential => {
                    const {
                        account
                    } = credential;
                    const {
                        lastUpdated
                    } = account;
                    const newAccount = formatAccount({
                        account,
                        credential,
                        formatFn: this.cleanCurrencyWithMwc,
                        showPending,
                        translateFn: this.translateWithMwc
                    });

                    // Generate a human-readable timestamp
                    newAccount.displayLastUpdated = formatLastUpdatedDate(lastUpdated, this.dateNow);

                    return newAccount;
                })
                : [];

            const getAccountIndexById = (accountId, isinvestmentAccount) => {
                let returnIndex = -1;
                const formattedAccountId = parseInt(accountId, 10);

                accountList.forEach((account, index) => {
                    if ((isinvestmentAccount && accountId === `${account.formattedAccountNumber}_${account.id}`)
                        || account.id === accountId || account.id === formattedAccountId) {
                        returnIndex = index;
                    }
                });

                return returnIndex;
            };

            const assets = this.getAssets();
            const insurance = this.getInsurance();
            const lastUpdated = '';
            const liabilities = this.getLiabilities();
            const addAccountsBySource = (source, root, financialItemType) => {
                if (!isValidArray(source)) {
                    return;
                }

                source.forEach(account => {
                    const {
                        importFinancialId, isInvestment,
                        importAccountNumber
                    } = account;
                    const newAccount = this.formatNetWorthAccount(account, lastUpdated, root, financialItemType);
                    const investmentAccount = ((newAccount.displayTypeName === DISPLAY_TYPES.INVESTMENTS)
                    && !importFinancialId);
                    const updateImportFinancialId = investmentAccount ? importAccountNumber : importFinancialId;
                    const baaIndex = getAccountIndexById(updateImportFinancialId, investmentAccount);
                    const isUnlinked = updateImportFinancialId
                        && baaIndex === -1;
                    let linkingInstitutionAccount = false;

                    if (hasLinkingInstitutions) {
                        // eslint-disable-next-line max-len
                        linkingInstitutionAccount = linkingInstitutions.find(al => al.credentialAccountId === newAccount.id) !== undefined;
                    }

                    if (!isInvestment && isUnlinked) {
                        newAccount.source = 'unlinked';
                    }

                    if (baaIndex > -1) {
                        const baaAccount = accountList[baaIndex];
                        accountList[baaIndex] = mergeAccounts(baaAccount, newAccount, this.translateWithMwc);
                    } else if (!linkingInstitutionAccount) {
                        accountList.push(newAccount);
                    }
                });
            };

            // @todo Unclear when to pass `financialItemType: 5`
            addAccountsBySource(assets, 'assets', 3);
            addAccountsBySource(insurance, 'insurance', 6);
            addAccountsBySource(liabilities, 'liabilities', 4);

            return accountList;
        },
        allDisplayTypes() {
            const { allAccounts } = this;
            const mappedTypes = allAccounts
                .map(account => ({
                    name: account.displayType,
                    ordinal: DISPLAY_TYPE_ORDER.get(account.displayTypeName)
                }));

            // The fastest comparison between two objects is to stringify them
            const stringifiedTypes = mappedTypes.map(type => JSON.stringify(type));

            // Only return unique types, sorted by their ordinal
            return mappedTypes
                .filter((displayType, index) => index === stringifiedTypes.indexOf(JSON.stringify(displayType)))
                .sort((displayTypeA, displayTypeB) => {
                    if (displayTypeA.ordinal < displayTypeB.ordinal) {
                        return -1;
                    }

                    if (displayTypeB.ordinal < displayTypeA.ordinal) {
                        return 1;
                    }

                    return 0;
                });
        },
        allErrors() {
            const {
                credentialErrors,
                dataErrorsParsed,
                baaDataErrors
            } = this;

            const credentialErrorArray = isValidArray(credentialErrors)
                ? credentialErrors
                : [];
            const dataErrorArray = isValidArray(dataErrorsParsed)
                ? dataErrorsParsed
                : [];
            const baaDataErrorArray = isValidArray(baaDataErrors)
                ? baaDataErrors
                : [];

            return [
                ...credentialErrorArray,
                ...dataErrorArray,
                ...baaDataErrorArray
            ];
        },
        baaDataErrors() {
            const { baaDataError } = this.labels;
            const errorData = [{
                id: 1,
                displayActionItems: false,
                position: 'top',
                type: 'warning',
                message: baaDataError
            }];
            if (this.showBaaDataError) {
                return errorData;
            }
            return [];
        },
        baaCredentials() {
            const { baaData } = this;

            return getCredentialsFromResponse(baaData);
        },
        componentProfile() {
            return {
                id: this.mwcId
            };
        },
        containerClass() {
            const { breakpointClass, showAccountsBalance } = this;
            const classes = [breakpointClass];

            if (!showAccountsBalance) {
                classes.push('pfm-account-list--no-sub-header');
            }

            return classes.join(' ');
        },
        unRegisteredFIs() {
            const { baaCredentials } = this;
            const unregisteredFIList = baaCredentials
                .filter(acc => acc.unifiedStatusInfoType === 'fiNotYetSupported'
                    || acc.unifiedStatusInfoType === 'fiRequestMissingElements')
                .map(institution => {
                    const { account } = institution;
                    return formatInstitution({
                        account,
                        institution,
                        translateFn: this.translateWithMwc
                    });
                });
            return unregisteredFIList;
        },
        credentialErrors() {
            const { baaCredentials } = this;

            return baaCredentials.reduce((result, credential) => {
                const {
                    financialInstitution = {
                        name: ''
                    },
                    id,
                    position = 'bottom',
                    unifiedStatusInfoType
                } = credential;

                let hasMultipleAccounts = false;
                const errorIndex = result.findIndex(el => el.id === id);
                if (errorIndex >= 0) {
                    result.splice(errorIndex, 1);
                    hasMultipleAccounts = true;
                }

                if (unifiedStatusInfoType
                    && credentialIsComplete(credential)
                    && unifiedStatusInfoType !== 'fiNotYetSupported'
                    && unifiedStatusInfoType !== 'fiRequestMissingElements'
                    && id) {
                    const message = this.translateWithMwc(unifiedStatusInfoType, {
                        institutionName: financialInstitution.name,
                        loginTerm: financialInstitution.loginTerm,
                        pwTerm: financialInstitution.pwTerm
                    });
                    const errorType = getCredentialErrorType(credential);
                    const msgType = errorType === 'NOT_USER_RESOLVABLE' ? 'warning' : 'error';
                    const errorButtonLabel = ERROR_BUTTON_LABELS[errorType];
                    const errorObject = {
                        errorType,
                        errorButtonLabel,
                        id,
                        message,
                        position,
                        unifiedStatusInfoType,
                        dismissed: false,
                        hasMultipleAccounts,
                        name: financialInstitution.name,
                        originalCredential: credential,
                        type: msgType
                    };

                    result.push(errorObject);
                }

                return result;
            }, []);
        },
        dataErrorsParsed() {
            return this.options.dataErrors;
        },
        displayNetWorthData() {
            return this.options.netWorthData;
        },
        emptyHasMarkup() {
            return hasMarkup(this.labels.noLinkedAccountsHTML);
        },
        emptyTextClass() {
            const { settings } = this;
            const { emptyTextAlignment } = settings;

            if (emptyTextAlignment === 'left') {
                return 'pfm-account-list__empty--left-aligned';
            }

            if (emptyTextAlignment === 'right') {
                return 'pfm-account-list__empty--right-aligned';
            }

            return '';
        },
        errorIconClass() {
            const classNames = [
                'mds-icon',
                'mds-icon--s',
                'pfm-account-list__account-status-icon',
                'pfm-account-list__account-status-icon--error'
            ];

            return classNames.join(' ');
        },
        hasAccounts() {
            const { allAccounts } = this;

            return isValidArray(allAccounts) && allAccounts.length > 0;
        },
        hasBaaNotification() {
            const { dataErrorsParsed } = this;

            return isValidArray(dataErrorsParsed) && dataErrorsParsed.length > 0;
        },
        hasLinkingInstitutions() {
            const { linkingInstitutions } = this;

            return isValidArray(linkingInstitutions) && linkingInstitutions.length > 0;
        },
        isSyncInProgress() {
            return isTruthy(this.accountsSyncing);
        },
        labels() {
            return {
                accountDetail: {
                    deleted: this.translateWithMwc('accountDetail.deleted'),
                    unlinked: this.translateWithMwc('accountDetail.unlinked')
                },
                accounts: this.translateWithMwc('accounts'),
                assetText: this.accountListView === 'vital-signs-nw'
                    ? this.translateWithMwc('assetTextNw')
                    : this.translateWithMwc('assetTextMos'),
                ariaAccounts: this.translateWithMwc('ariaAccounts'),
                ariaAccountIsLinking: this.translateWithMwc('ariaAccountIsLinking'),
                ariaAccountsBalance: this.translateWithMwc('ariaAccountsBalance'),
                ariaAccountInfoLoading: this.translateWithMwc('ariaAccountInfoLoading'),
                ariaAccountInfoLoaded: this.translateWithMwc('ariaAccountInfoLoaded'),
                ariaAccountBalance: this.translateWithMwc('ariaAccountBalance'),
                ariaAccountDetailsBtn: this.translateWithMwc('ariaAccountDetailsBtn'),
                ariaAccountDisplayType: this.translateWithMwc('ariaAccountDisplayType'),
                ariaAccountFourDigit: this.translateWithMwc('ariaAccountFourDigit'),
                ariaAccountUpdatedAt: this.translateWithMwc('ariaAccountUpdatedAt'),
                ariaAccountSyncing: this.translateWithMwc('ariaAccountSyncing'),
                ariaDismissAccountBtn: this.translateWithMwc('ariaDismissAccountBtn'),
                ariaDescAccountType: this.translateWithMwc('ariaDescAccountType'),
                ariaInstitutionName: this.translateWithMwc('ariaInstitutionName'),
                ariaNeedsAttention: this.translateWithMwc('ariaNeedsAttention'),
                backToAccountList: this.translateWithMwc('backToAccountList'),
                contacting: this.translateWithMwc('contacting'),
                contactSupport: this.translateWithMwc('contactSupport'),
                debtText: this.translateWithMwc('debtTextNw'),
                deleteAccount: this.translateWithMwc('deleteAccount'),
                deleteCredentials: this.translateWithMwc('deleteCredentials'),
                deletedAccount: this.translateWithMwc('deletedAccount'),
                editRequest: this.translateWithMwc('editRequest'),
                errorsHeading: this.translateWithMwc('errorsHeading'),
                finishingUp: this.translateWithMwc('finishingUp'),
                investmentTooltipText: this.translateWithMwc('investmentTooltipText'),
                linkAccount: this.translateWithMwc('linkAccount'),
                linking: this.translateWithMwc('linking'),
                noLinkedAccounts: this.translateWithMwc('noLinkedAccounts'),
                noLinkedAccountsText: this.translateWithMwc('noLinkedAccountsText'),
                noLinkedAccountsGeneric: this.translateWithMwc('noLinkedAccountsGeneric'),
                noLinkedAccountsHTML: sanitizeHtml(this.translateWithMwc('noLinkedAccountsHTML')),
                notUserResolvable: this.translateWithMwc('notUserResolvable'),
                pending: this.translateWithMwc('pending'),
                reenterButtonText: this.translateWithMwc('reenterButtonText'),
                requestedFI: this.translateWithMwc('requestedFI'),
                syncing: this.translateWithMwc('syncing'),
                unlinkAccount: this.translateWithMwc('unlinkAccount'),
                unlinked: this.translateWithMwc('unlinked'),
                withdrawRequest: this.translateWithMwc('withdrawRequest'),
                accountsTooltip: this.translateWithMwc('accountsTooltip'),
                baaDataError: this.translateWithMwc('baaDataError')
            };
        },
        linkingInstitutions() {
            const { baaCredentials } = this;

            // Not every `credential` has an `account`
            return baaCredentials
                .filter(credential => (!credentialHasAccount(credential)
                    || !accountHasType(credential.account) || !accountIsModified((credential.account)))
                    && credential.activityInProgressStatus === IN_PROGRESS)
                .map(credential => {
                    const {
                        account,
                        activityInProgressStatus,
                        financialInstitution,
                        unifiedStatusInfoMsg,
                        unifiedStatusInfoShortType
                    } = credential;
                    const { id, name } = financialInstitution;
                    const key = this.getUniqueID('Account_', id);
                    let accountId;
                    let dictionaryValue;
                    let subtitle;

                    // 'Complete' is another value that can be provided, but then the account should not be here
                    if (activityInProgressStatus === IN_PROGRESS) {
                        subtitle = this.translateWithMwc('contacting');
                    }

                    // Look up a key in the component il8n strings
                    if (!subtitle && unifiedStatusInfoShortType) {
                        dictionaryValue = this.translateWithMwc(unifiedStatusInfoShortType);

                        if (dictionaryValue && dictionaryValue !== '') {
                            subtitle = dictionaryValue;
                        }
                    }

                    // Trust the string returned by BAA
                    if (!subtitle && unifiedStatusInfoMsg) {
                        subtitle = unifiedStatusInfoMsg;
                    }

                    if (isValidObject(account) && Object.keys(account).length > 0) {
                        accountId = account.id;
                    }

                    const { ariaAccountIsLinking } = this.labels;
                    screenReaderStatus(`Account ${name} ${ariaAccountIsLinking}`);

                    return {
                        accountNumber: '',
                        aggregationStatusErrorCode: null,
                        category: '',
                        credentialAccountId: accountId,
                        displayLastUpdated: '',
                        displayType: '',
                        errorMessage: '',
                        id,
                        institutionId: id,
                        institutionName: name,
                        key,
                        lastUpdated: '',
                        name,
                        originalValue: 0,
                        source: 'aggregation',
                        subtitle,
                        type: '',
                        uimId: '',
                        value: 0
                    };
                });
        },
        loadingParsed() {
            const { loading, baaDataLoaded } = this;

            const loadingParsedRes = validBoolean((loading || !baaDataLoaded), this.settings.loading);
            if (!loadingParsedRes) {
                const { ariaAccountInfoLoaded } = this.labels;
                screenReaderStatus(ariaAccountInfoLoaded);
            }

            return loadingParsedRes;
        },
        noLinkedAccountsLabel() {
            const { useBaa } = this;

            return useBaa === true
                ? this.translateWithMwc('noLinkedAccounts')
                : this.translateWithMwc('noLinkedAccountsGeneric');
        },
        nonInvestmentAccounts() {
            const accounts = this.allAccounts.filter(account => isAccountAggregated(account)
                && !isInvestmentAccountType(account.accountType));

            return accounts;
        },
        notificationStyle() {
            const { settings } = this;

            return settings.notificationStyle;
        },
        pendingAccounts() {
            return this.allAccounts.filter(account => account.isPending);
        },
        readMode() {
            const { settings } = this;

            return validBoolean(settings.readMode, false);
        },
        showAccounts() {
            const {
                accountOfInterest,
                hasAccounts,
                hasLinkingInstitutions,
                loadingParsed
            } = this;

            if (isTruthy(loadingParsed)) {
                return false;
            }

            return !accountOfInterest && (hasAccounts || hasLinkingInstitutions);
        },
        showAccountsBalance() {
            const { settings } = this;

            return validBoolean(settings.showAccountsBalance, true);
        },
        showEmpty() {
            const { hasAccounts, hasLinkingInstitutions, loadingParsed } = this;

            if (isTruthy(loadingParsed)) {
                return false;
            }

            return !hasAccounts && !hasLinkingInstitutions;
        },
        showEmptyStateIcon() {
            const { settings } = this;

            return validBoolean(settings.showEmptyStateIcon, true);
        },
        /**
         * @property showExternalNotifications
         * @description Show BAA notifications externally, for example, in modals.
         */
        showExternalNotifications() {
            const {
                allErrors,
                notificationStyle,
                displayErrors
            } = this;

            return notificationStyle === 'external' && displayErrors
                && allErrors.length > 0;
        },
        /**
         * @property showInternalNotifications
         * @description Show BAA notifications within the component.
         */
        showInternalNotifications() {
            const {
                allErrors,
                notificationStyle,
                displayErrors
            } = this;

            return notificationStyle === 'internal' && displayErrors
                && allErrors.length > 0;
        },
        showInvestmentTooltip() {
            const { settings } = this;

            return validBoolean(settings.showInvestmentTooltip, false);
        },
        showLinkAccount() {
            const { settings } = this;

            if (settings.useBaa === false) {
                return false;
            }

            return validBoolean(settings.showLinkAccount, true);
        },
        showPending() {
            return this.settings.showPending;
        },
        showSupportModal() {
            const { settings } = this;

            return validBoolean(settings.showSupportModal, false);
        },
        useBaa() {
            const { settings } = this;

            return validBoolean(settings.useBaa, true);
        },
        warningIconClass() {
            const classNames = [
                'mds-icon',
                'mds-icon--s',
                'pfm-account-list__account-status-icon',
                'pfm-account-list__account-status-icon--warning'
            ];

            return classNames.join(' ');
        }
    },
    data() {
        return {
            version,
            accountOfInterest: null,
            dateNow: new Date(),
            defaultConfig: {
                intlNamespace: this.mwcId,
                ...DEFAULT_CONFIG
            },
            namespace: 'pfm-account-list',
            refreshErrorCredential: false,
            timerForDateUpdate: null,
            showBaaDataError: false,
            displayErrors: true,
            isPendingRequest: false
        };
    },
    methods: {
        svgIcon(name) {
            return icons[name];
        },
        emitDismissAccount() {
            this.accountOfInterest = null;
            this.displayErrors = true;
            this.focusFirstAccountViewDetailBtn();
        },
        emitDeleteCredential(data) {
            const { id, mwcId } = data;

            this.$emit('baa-delete-credential', {
                id,
                mwcId
            });
        },
        emitLinkAccount() {
            this.$emit('pfm-link-account');
        },
        emitShowDeleteAccount(account) {
            const { nonInvestmentAccounts } = this;
            const { isInvestment } = account;
            const resetStatus = isInvestment === false
                && nonInvestmentAccounts.length === 1;

            this.$emit('pfm-show-delete-account', {
                ...account,
                resetStatus
            });
        },
        emitShowSupportModal(eventData) {
            this.$emit('pfm-show-support-modal', eventData);
        },
        emitShowUnlinkAccount(account) {
            const { nonInvestmentAccounts } = this;
            const { isInvestment } = account;
            const resetStatus = isInvestment === false
                && nonInvestmentAccounts.length === 1;

            this.$emit('pfm-show-unlink-account', {
                ...account,
                resetStatus
            });
        },
        emitUpdateCredential(eventData) {
            this.$emit('pfm-update-credential', {
                route: `/edit-credential;credentialId=${eventData.id};showProgressStep=false`
            });
        },
        emitDiscoverAccounts(eventData) {
            this.$emit('pfm-update-credential', {
                route: `/discover-accounts;credentialId=${eventData.id};showProgressStep=false`
            });
        },
        formatNetWorthAccount(account, lastUpdated, root, financialItemType) {
            const newAccount = formatAccount({
                account: {
                    ...account,
                    financialItemType,
                    lastUpdated,
                    root
                },
                formatFn: this.cleanCurrencyWithMwc,
                source: 'net-worth',
                translateFn: this.translateWithMwc
            });

            // Generate a human-readable timestamp
            newAccount.displayLastUpdated = formatLastUpdatedDate(lastUpdated, this.dateNow);

            return newAccount;
        },
        getAssets() {
            return resolveAccounts(ASSET_BITS, this.displayNetWorthData.assets);
        },
        getInsurance() {
            return resolveAccounts(INSURANCE_BITS, this.displayNetWorthData.insurance);
        },
        getLiabilities() {
            return resolveAccounts(LIABILITY_BITS, this.displayNetWorthData.liabilities);
        },
        setAccountOfInterest(account, isRequestedFI = false) {
            this.accountOfInterest = account;
            this.displayErrors = false;
            this.isPendingRequest = isRequestedFI;
        },
        updateTimeForAccountDate() {
            clearInterval(this.timerForDateUpdate);

            this.timerForDateUpdate = setInterval(() => {
                this.dateNow = new Date();
            }, 60000);
        },
        focusFirstAccountViewDetailBtn() {
            forceNextTick(() => {
                const firstAccountCaret = document.querySelector('.pfm-account-list__accounts').querySelectorAll('div')[0].querySelectorAll('.pfm-account-list__account-action')[0].querySelector('button');
                if (!firstAccountCaret) { return; }
                firstAccountCaret.focus();
            });
        }
    },
    mixins: [PfmUIMixin, AccountListMixin],
    async mounted() {
        this.$nextTick(() => {
            this.$emit('pfm-component-rendered', this.componentProfile);
        });
    },
    name: mwcComponentName,
    props: {
        accountsSyncing: {
            default: false,
            type: [String, Boolean]
        },
        baaData: {
            default: () => ({
                data: []
            }),
            type: [String, Object, Array]
        },
        config: {
            default: () => ({}),
            type: [String, Object]
        },
        dataErrors: {
            type: [String, Array]
        },
        deletedNetWorthAccount: {
            type: Object
        },
        loading: {
            default: undefined,
            type: Boolean
        },
        mwcId: {
            required: true,
            type: String
        },
        netWorthData: {
            default: () => ({}),
            type: [String, Object]
        },
        unlinkedBaaAccount: {
            type: Object
        },
        baaDataLoaded: {
            default: false,
            type: Boolean
        }
    },
    provide() {
        const keys = [
            'mwcId'
        ];
        const provider = {};

        keys.forEach(key => {
            Object.defineProperty(provider, key, {
                enumerable: true,
                get: () => this[key]
            });
        });

        return provider;
    },
    watch: {
        baaData(newData, oldData) {
            if (oldData && newData !== oldData) {
                this.$emit('pfm-baa-data-loaded', {
                    newData,
                    oldData,
                    id: this.mwcId
                });
                this.setLastDataRefresh();
                // this.emitDismissAccount();
                this.accountOfInterest = null;
                this.displayErrors = true;
            }

            this.showBaaDataError = newData === 'undefined';

            this.$nextTick(() => {
                const {
                    credentialErrors,
                    mwcId,
                    notificationStyle
                } = this;

                if (notificationStyle !== 'events'
                    || !isValidArray(credentialErrors)
                    || credentialErrors.length === 0) {
                    return;
                }

                this.$emit('pfm-credential-errors', {
                    credentialErrors,
                    id: mwcId
                });
            });
        },
        deletedNetWorthAccount(newData, oldData) {
            if (oldData && newData !== oldData) {
                this.setLastDataRefresh();
            }
        },
        netWorthData(newData, oldData) {
            if (oldData && newData !== oldData) {
                this.$emit('pfm-net-worth-data-loaded', {
                    newData,
                    oldData,
                    id: this.mwcId
                });
                this.setLastDataRefresh();
            }
        },
        unlinkedBaaAccount(newData, oldData) {
            if (oldData && newData !== oldData) {
                this.setLastDataRefresh();
            }
        }
    }
};
</script>

<style lang="scss" module>
@import '~cwp-dev-shared/styles/variables';
@import '~pfm-shared/styles/mixins';

@mixin pfm-account-list-flex-row () {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

@mixin pfm-account-list-reset-space ($margin: 0, $padding: 0) {
    margin: $margin;
    padding: $padding;
}

:global {
    .pfm-account-list .pfm-account-list__header-wrapper {
        display: flex;
        align-items: baseline;
        .key-datapoints__info-popover {
            margin-left: 5px;
        }
    }
    .pfm-account-list {
        @include pfm-component();

        @media (max-width: $screen-sm-min) {
            margin-top: $mds-space-4-x;
        }

        &__account,
        &__display-type,
        &__unlinked-account,
        &__unlinked-accounts {
            &-sub-title,
            &-title,
            &-updated,
            &-value {
                @include pfm-account-list-reset-space();
            }

            &-sub-title {
                @include mds-body-text-s();
                color: $mds-text-color-secondary-on-light;
            }

            &-title {
                @include mds-body-text-m();
            }

            &-updated {
                @include mds-body-text-s();
                color: $mds-text-color-secondary-on-light;
            }

            &-value {
                @include mds-body-text-m();
            }
        }

        &__account {
            @include pfm-account-list-flex-row();
            border-bottom: $mds-border-separator-on-white;
            padding: $mds-space-1-x 0 $mds-space-1-x $mds-space-1-x;

            &-action {
                margin-left: $mds-space-2-x;
                width: $mds-space-2-x;

                [role=icon] {
                    border: none;
                }
            }

            &-content {
                flex-grow: 2;
                overflow: hidden;
            }

            &-institution {
                overflow: hidden;
                text-overflow: clip;
                white-space: nowrap;
            }

            &-metadata {
                flex-grow: 1;
                margin-left: $mds-space-1-x;
                text-align: right;
            }

            &-number {
                white-space: nowrap;
            }

            &-status-icon {
                // Can't use the $mds-space-half-x constant
                margin: 0 0 -3px $mds-space-1-x;

                &--error {
                    color: $mds-feedback-color-error;
                    fill: $mds-feedback-color-error;
                }

                &--warning {
                    color: $mds-feedback-color-warning;
                    fill: $mds-feedback-color-warning;
                }
            }

            &-status-spinner {
                display: inline-block;
                height: $mds-space-2-x;
                margin: 0 0 0 $mds-space-1-x;
                width: $mds-space-2-x;

                &-container {
                    // # Using !important because these pfm-account-lists have scoped styles
                    display: inline-block;
                    height: 100% !important;
                    margin-bottom: -2px;
                    transform: scale(0.46);
                    width: 100% !important;
                }
            }

            &-sub-title {
                @include pfm-account-list-flex-row();
            }

            &:last-of-type {
                border-bottom: 0;
            }
        }

        &__accounts,
        &__unlinked-accounts {
            margin-top: $mds-space-3-x;

            + .pfm-account-list {
                &__button {
                    margin-top: $mds-space-4-x;
                }
            }
        }

        &__bullet {
            @include mds-body-text-s();
            color: $mds-color-neutral-67;
        }

        &__display-type,
        &__unlinked-accounts {
            &-title {
                font-weight: $mds-font-weight-bold;
            }
        }

        &__display-type {
            + .pfm-account-list {
                &__display-type {
                    border-top: $mds-border-separator-on-white;
                    margin-top: $mds-space-1-x;
                    padding-top: $mds-space-1-x;
                }
            }
         }

        &__empty {
            padding: 0 0 $mds-space-2-x;
            text-align: center;

            &--left-aligned {
                text-align: left;

                .pfm-account-list__icon-list {
                    justify-content: flex-start;
                }
            }

            &--right-aligned {
                text-align: right;

                .pfm-account-list__icon-list {
                    justify-content: flex-end;
                }
            }

            &-label {
                @include pfm-account-list-reset-space();
            }

            &-text {
                @include mds-body-text-l();
            }
        }

        &__has-error {
            padding: 0 0 $mds-space-2-x;
            text-align: left;
        }

        &__header {
            @include pfm-component-title();
            @include pfm-account-list-reset-space();
        }

        &__link-account-button {
            margin-top: $mds-space-3-x !important;
        }

        &__sub-header {
            @include mds-level-5-heading();
            @include pfm-account-list-reset-space($padding: 0 0 $mds-space-2-x);
            margin-bottom: $mds-space-3-x;
            border-bottom: 2px solid #333;
        }

        &__unbreakable {
            display: inline-block;
            white-space: nowrap;
        }

        &__unlinked-account {
            @include pfm-account-list-flex-row();

            &-content {
                margin-left: $mds-space-2-x;
            }

            &-loader {
                width: 40px;
            }

            &-sub-title {
                margin-top: $mds-space-1-x;
            }

            + .pfm-account-list {
                &__unlinked-account {
                    margin-top: $mds-space-4-x;
                }
            }
        }

        &__unlinked-accounts {
            &-title {
                margin-bottom: $mds-space-3-x;
                margin-top: $mds-space-3-x;
            }

            + .pfm-account-list {
                &__accounts {
                    margin-top: $mds-space-4-x;
                }
            }
        }

        &__unlinked-tag {
            margin: $mds-space-1-x 0 0;
            width: 100%;
        }

        &--no-sub-header {
            .pfm-account-list__header {
                border-bottom: $mds-border-separator-on-title;
                @include pfm-account-list-reset-space($padding: 0 0 $mds-space-2-x);
            }
        }

        &__empty-text-html {
            padding: $mds-space-6-x 0 0;
        }

        &__icon-list {
            padding: $mds-space-2-x 0;
            display: flex;
            justify-content: center;
        }

        &__icon {
            padding: 0 $mds-space-1-x;
            span {
                width: $mds-space-7-x;
                display: block;
                svg {
                    width: 100%;
                }
            }
        }

        &__empty-label {
            font-size: $mds-font-size-l;
        }

        &__empty-sub-text {
            font-size: $mds-font-size-m;
            font-weight: 400;
            margin: 0 0 $mds-space-1-x;
        }

        *:focus {
            outline: $accessibility-focus-outline;
            box-shadow: $accessibility-focus-box-shadow;
        }
    }
}
</style>
