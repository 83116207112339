export default {
    aggregationAPI: '',
    aggregationHost: '',
    currencyId: 'USD',
    debugEnabled: false,
    emptyTextAlignment: '',
    disableEdit: false,
    languageId: 'en',
    linkDomain: '',
    loading: false,
    mdsSize: 'medium',
    mwcId: '',
    pfmApiEnv: 'prod'
};
