import MwcVueHelperMixin, { MwcConfigMixin, MwcResponsiveMixin } from 'mwc-vue-component-helper';
import PfmSharedMixin from './pfm-shared';
import { validString } from '../helpers/valid';

/**
 * Expands on the MWC mixins:
 * https://msstash.morningstar.com/projects/TECHSTD/repos/morningstar-web-components/browse/packages/mwc-vue-component-helper/src/mwc-vue-base-mixin.js
 * https://msstash.morningstar.com/projects/TECHSTD/repos/morningstar-web-components/browse/packages/mwc-vue-component-helper/src/mwc-vue-config-mixin.js
 */

/**
 * @class pfm-ui
 * @description A mixin for PFM MWC UI components
 */
export default {
    computed: {
        mdsComponentSize() {
            return this.options.mdsSize;
        }
    },
    data() {
        return {
            /**
             * @property {function} labeller This is eventually overwritten by MWC
             * The component fails unit tests due to a race condition without this
             */
            labeller: {
                getLabel(label) {
                    return label;
                }
            },
            namespace: 'pfm-ui-component',
            /**
             * @property {Array} providedProps A list of immutable component properties to share with children
             */
            providedProps: ['mdsComponentSize', 'mwcId']
        };
    },
    methods: {
        /**
         * @function cleanCurrencyWithMwc
         * @description Format a value as currency, and remove cents for whole values
         * @param {number} value The value to format
         * @returns {string} The formatted currency value
         */
        cleanCurrencyWithMwc(value) {
            const formattedValue = this.formatCurrencyWithMwc(value);
            const { languageId } = this.settings;

            // Strip cents/pence for English users
            if (validString(languageId).indexOf('en') === 0) {
                return formattedValue.replace('.00', '');
            }

            return formattedValue;
        }
    },
    mixins: [PfmSharedMixin, MwcVueHelperMixin, MwcConfigMixin, MwcResponsiveMixin],
    props: {
        config: {
            default: () => ({}),
            type: [String, Object]
        },
        mdsSize: {
            default: 'medium',
            type: String
        },
        mwcId: {
            required: true,
            type: String
        }
    },
    provide() {
        const { providedProps } = this;

        return providedProps.reduce((provider, obj) => {
            Object.defineProperty(provider, obj, {
                enumerable: true,
                get: () => this[obj] || this[`$_${obj}`]
            });

            return provider;
        }, {});
    }
};
