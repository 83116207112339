<template>
    <div class="baa-notification">
        <notification-wrapper
            :read-mode="readMode" />
    </div>
</template>

<script>
import NotificationWrapper from './NotificationWrapper';

export default {
    name: 'baa-notification',
    components: {
        NotificationWrapper
    },
    props: {
        config: {
            default: () => ({}),
            type: [String, Object]
        },
        mwcId: {
            required: true,
            type: String
        },
        notificationList: {
            default: () => ([]),
            type: Array
        },
        notUserResolvableMessage: {
            default: 'We can\'t connect to these accounts right now. Try adding them later.',
            type: String
        },
        readMode: {
            default: false,
            type: Boolean
        },
        reenterButtonText: {
            default: 'Reenter information',
            type: String
        }
    },
    created() {
        this.$mwcRegister(this);
    },
    /* istanbul ignore next */
    mounted() {
        document.addEventListener('cwp-notification-dismiss', this.dismissEvent);
        document.addEventListener('cwp-notification-secondary-action', this.secondaryActionEvent);

        this.addItem(this.notificationList);
    },
    watch: {
        notificationList(newValue) {
            this.addItem(newValue);
        }
    },
    /* istanbul ignore next */
    beforeDestroy() {
        document.removeEventListener('cwp-notification-dismiss', this.dismissEvent);
        document.removeEventListener('cwp-notification-secondary-action', this.secondaryActionEvent);
    },
    methods: {
        addItem(data) {
            if (!data) return;

            let nonActionStackIndex = -1;
            const contentList = data.reduce((result, item) => {
                const tempObj = {
                    isDismissible: true,
                    autoHide: {}.hasOwnProperty.call(item, 'autoHide') ? item.autoHide : true,
                    isTinted: true,
                    position: item.position || 'bottom',
                    timeout: 10000,
                    origin: this.$options._componentTag,
                    ...item
                };

                if (tempObj.errorType !== 'NOT_USER_RESOLVABLE') {
                    tempObj.buttonText = this.reenterButtonText;
                    tempObj.autoHide = false;
                    tempObj.message = this.$mwc.$t(tempObj.unifiedStatusInfoType, {
                        name: tempObj.name
                    });
                    result.push(tempObj);
                } else if (nonActionStackIndex === -1) {
                    nonActionStackIndex = result.length;
                    tempObj.autoHide = false;
                    tempObj.message = this.$mwc.$t(tempObj.unifiedStatusInfoType, {
                        name: tempObj.name
                    });
                    result.push(tempObj);
                } else {
                    if (!result[nonActionStackIndex].list) {
                        const tempItem = result[nonActionStackIndex];
                        /* eslint-disable no-param-reassign */
                        result[nonActionStackIndex].stack = true;
                        result[nonActionStackIndex].message = this.notUserResolvableMessage;
                        result[nonActionStackIndex].list = [{ name: tempItem.name, id: tempItem.id }];
                        /* eslint-enable no-param-reassign */
                    }
                    result[nonActionStackIndex].list.push({ name: tempObj.name, id: tempObj.id });
                }
                return result;
            }, []);
            /* istanbul ignore next */
            // @todo: Not working: revisit
            document.dispatchEvent(new CustomEvent('cwp-notification-content', {
                detail: {
                    [this.$options._componentTag]: contentList
                }
            }));
        },
        deleteItem(data, emitUnlinkEvent) {
            const dataList = data.list || [{ id: data.id }];

            dataList.forEach(element => {
                this.notificationList.some((item, index) => {
                    if (element.id === item.id) {
                        this.notificationList.splice(index, 1);
                        if (emitUnlinkEvent) {
                            this.$emit('pfm-delete-credential', { id: element.id });
                        }
                    }
                    return element.id === item.id;
                });
            });

            this.addItem(this.notificationList);
        },
        dismissEvent(event) {
            const data = event.detail;
            if (data.origin === this.$options._componentTag) {
                this.deleteItem(data, true);
            }
        },
        secondaryActionEvent(event) {
            const data = event.detail;
            if (data.origin === this.$options._componentTag) {
                this.$emit('pfm-update-credential', data);
                this.deleteItem(data);
            }
        }
    }
};
</script>
