import { resolveBits } from './data';
import { DISPLAY_TYPES, getAccountType, getDisplayType } from './display-type';
import {
    isOfType,
    isValidArray,
    isValidObject,
    isValidString,
    validNumber,
    validObject,
    validString
} from './valid';

/**
 * @property {object} ACCOUNT_NUMBER_REGEX A RegEx to split an account name/number
 * @const
 */
const ACCOUNT_NUMBER_REGEX = /( )([X]{1,})([\d]{3,})/;

/**
 * @property {object} ACCOUNT_SOURCES A collection of account sources
 * @const
 */
export const ACCOUNT_SOURCES = {
    AGGREGATION: 'aggregation',
    COMBINED: 'combined',
    NET_WORTH: 'net-worth',
    UNLINKED: 'unlinked'
};

/**
 * @property {object} AGGREGATION_STATUS_ERROR_CODES A collection of account aggregation status error codes
 * @const
 */
export const AGGREGATION_STATUS_ERROR_CODES = {
    1003: 'Inaccessible',
    1004: 'No Holdings or Balances',
    1005: 'Complete',
    1007: 'Error'
};

/**
 * @property {array} ASSET_BITS A collection of asset bits for locating net worth accounts
 * @const
 */
export const ASSET_BITS = [
    'taxDeferredAssets.collegeSavingPlans.coverdell.items',
    'taxDeferredAssets.collegeSavingPlans.type529.items',
    'taxDeferredAssets.otherTaxDeferredInvestments.annuities.items',
    'taxDeferredAssets.otherTaxDeferredInvestments.cashValueLife.accountCashValueLife.items',
    'taxDeferredAssets.otherTaxDeferredInvestments.cashValueLife.cashValueUniversalLife.items',
    'taxDeferredAssets.otherTaxDeferredInvestments.cashValueLife.cashValueVariableLife.items',
    'taxDeferredAssets.otherTaxDeferredInvestments.cashValueLife.cashValueWholeLife.items',
    'taxDeferredAssets.retirementAssets.hybridPlans.sarsepira.items',
    'taxDeferredAssets.retirementAssets.hybridPlans.sepira.items',
    'taxDeferredAssets.retirementAssets.hybridPlans.simple401K.items',
    'taxDeferredAssets.retirementAssets.hybridPlans.simpleIRA.items',
    'taxDeferredAssets.retirementAssets.ira.traditionalIRA.items',
    'taxDeferredAssets.retirementAssets.nonQualifiedPlans.type457Plan.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.account529.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.accountBeneficiaryIRA.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.accountTaxDeferred.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.beneficiaryIRA.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.decedentIRA.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.deferredCompensationplan.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.definedBenefit.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.excessBenefit.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.keogh.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.moneyPurchasePensionPlan.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.nonCustodialRetire.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.profitSharing.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.rolloverIRA.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.salaryReduce.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.serp.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.stockBonusPlans.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.suppPlan.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.targetBenefit.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.topHat.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.type401A.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.type401K.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.type403B.items',
    'taxDeferredAssets.retirementAssets.qualifiedPlans.variableAnnuity.items',
    'taxFreeAssets.corperationNonTaxible.corperationNonTaxible.items',
    'taxFreeAssets.ira.rothConvertIRA.items',
    'taxFreeAssets.ira.rothIRA.items',
    'taxFreeAssets.muniBonds.items',
    'taxFreeAssets.otherTaxfreeAssets.items',
    'taxFreeAssets.uSSavingsBonds.items',
    'taxableAssets.bankAccountCDs.items',
    'taxableAssets.bankAccountMoneyMarkets.items',
    'taxableAssets.checkingAccounts.items',
    'taxableAssets.corperationTaxable.items',
    'taxableAssets.emergencyFund.items',
    'taxableAssets.investmentAccounts.items',
    'taxableAssets.moneyMarketAccounts.items',
    'taxableAssets.othersTaxableAssets.items',
    'taxableAssets.ownershipInPrivateCompany.items',
    'taxableAssets.partnership.items',
    'taxableAssets.passivePartnership.items',
    'taxableAssets.realEstate.items',
    'taxableAssets.savingsAccounts.items',
    'taxableAssets.soleProprietorship.items',
    'taxableAssets.taxableBonds.items'
];

/**
 * @property {object} ERROR_MESSAGE_KEYS A collection of aggregation status error codes grouped by user resolution
 * @const
 */
export const ERROR_MESSAGE_KEYS = {
    NOT_USER_RESOLVABLE: [
        'accountClosed',
        'baaCallFail',
        'badAccountCheckLater',
        'badAccountVerifyAccountNum',
        'cannotConnectMissingAcctNum',
        'cannotConnectOauthAcctNotAuthorized',
        'cannotConnectOauthNotConfigured',
        'cannotLoadDataCheckLater',
        'cannotLoadDataCheckLaterShort',
        'cannotVerifyFiLimitation',
        'specifiedAccountNotFound',
        // Need to confirm if these error types are valid
        'badAccountVerifyAccountNumShort',
        'cannotVerifyAcctNumShort',
        'failedVerifyAccount',
        'cannotConnectMissingAcctNumShort',
        'missingAccountNumber'
    ],
    USER_RESOLVABLE_TYPE_1: [
        'cannotConnectBadLoginPw',
        'cannotConnectBadLoginPwIsac',
        'cannotConnectBadLoginPwSqa',
        'cannotConnectBadLoginPwSqaIsac',
        'cannotConnectNotAuthorized',
        'incorrectCredentials'
    ],
    USER_RESOLVABLE_TYPE_2: [
        'cannotConnectUserActionPersonalInfo',
        'cannotConnectUserActionSecurity',
        'cannotConnectUserActionUserAgreement',
        'cannotConnectUserActionOnetimeCode'
    ],
    USER_RESOLVABLE_TYPE_3: [
        'cannotConnectMissingLoginPW',
        'noConnectAttemptYet'
    ],
    USER_RESOLVABLE_TYPE_4: [
        'fiRequestMissingElements'
    ],
    USER_RESOLVABLE_TYPE_5: [
        'credHasNoAccounts'
    ]
};

/**
 * @property {object} ERROR_BUTTON_LABELS A collection of insurance bits for locating net worth accounts
 * @const
 */
export const ERROR_BUTTON_LABELS = {
    USER_RESOLVABLE_TYPE_1: 'Re-authenticate',
    USER_RESOLVABLE_TYPE_2: 'External action required',
    USER_RESOLVABLE_TYPE_3: 'Authenticate',
    USER_RESOLVABLE_TYPE_4: 'Complete request',
    USER_RESOLVABLE_TYPE_5: 'Connect Accounts'
};

/**
 * @property {array} INSURANCE_BITS A collection of insurance bits for locating net worth accounts
 * @const
 */
export const INSURANCE_BITS = [
    'deathBenefits.lifeInsuranceBenefits.items',
    'otherInsurance.disabilityInsurance.items',
    'otherInsurance.ltc.items',
    'otherInsurance.nonCashValueLifeInsurance.items',
    'otherInsurance.pc.items',
    'otherInsurance.umbrellaPolicy.items'
];

/**
 * @property {array} LIABILITY_BITS A collection of liability bits for locating net worth accounts
 * @const
 */
export const LIABILITY_BITS = [
    'liabilitiesByType.businessLoans.businessLoan.items',
    'liabilitiesByType.businessLoans.othersBusinessLoan.items',
    'liabilitiesByType.collateralizedPersonalLoans.boatLoan.items',
    'liabilitiesByType.collateralizedPersonalLoans.carLoan.items',
    'liabilitiesByType.collateralizedPersonalLoans.collateralLoan.items',
    'liabilitiesByType.collateralizedPersonalLoans.homeEquityLineOfCredit.items',
    'liabilitiesByType.collateralizedPersonalLoans.mortgages.items',
    'liabilitiesByType.nonCollateralizedPersonalLoans.contingencyLiabilities.items',
    'liabilitiesByType.nonCollateralizedPersonalLoans.creditCardDebt.items',
    'liabilitiesByType.nonCollateralizedPersonalLoans.marginDebt.items',
    'liabilitiesByType.nonCollateralizedPersonalLoans.nonCollateralLoan.items',
    'liabilitiesByType.nonCollateralizedPersonalLoans.otherNonCollateral.items',
    'liabilitiesByType.nonCollateralizedPersonalLoans.personalLineOfCredit.items',
    'liabilitiesByType.nonCollateralizedPersonalLoans.personalNote.items',
    'liabilitiesByType.nonCollateralizedPersonalLoans.studentLoan.items'
];

/**
 * @property {array} LIABILITY_TYPES A collection of liability types
 * @const
 */
export const LIABILITY_TYPES = [
    DISPLAY_TYPES.CREDIT_CARDS,
    DISPLAY_TYPES.LOANS
];

/**
 * @function canDeleteAccount
 * @description Determines if a valid account can be deleted from Office/PFM
 * @param {object} account The account to verify
 * @returns {boolean} Can the account be deleted?
 */
export function canDeleteAccount(account) {
    if (!isValidObject(account)) {
        return false;
    }

    const {
        financialItemId,
        financialItemType,
        importExternalId,
        isInvestment
    } = account;

    if (isInvestment) {
        return false;
    }

    if (importExternalId || (financialItemId && financialItemType)) {
        return true;
    }

    return false;
}

/**
 * @function canUnlinkAccount
 * @description Determines if a valid account can be unlinked from ByAllAccounts
 * @param {object} account The account to verify
 * @returns {boolean} Can the account be unlinked?
 */
export function canUnlinkAccount(account) {
    if (!isValidObject(account)) {
        return false;
    }

    const {
        baaAccountId
    } = account;

    if (baaAccountId) {
        return true;
    }

    return false;
}

/**
 * @function compareAccounts
 * @description Compares two account objects based on a set of keys
 * @param {object} firstAccount The first account to compare
 * @param {object} secondAccount The second account to compare
 * @param {array} keys A list of keys to compare with
 * @returns {boolean} Are the accounts a match?
 */
export function compareAccounts(firstAccount, secondAccount, keys = [
    'baaAccountId',
    'financialItemId',
    'id',
    'importExternalId',
    'name',
    'title',
    'value'
]) {
    if (!isValidObject(firstAccount) || !isValidObject(secondAccount)) {
        return false;
    }

    let match = true;

    keys.forEach(key => {
        const firstValue = firstAccount[key];
        const secondValue = secondAccount[key];

        if (firstValue !== secondValue) {
            match = false;
        }
    });

    return match;
}

/**
 * @function accountHasBalances
 * @description Determines if a BAA account has balances
 * @param {*} account The BAA account to verify
 * @param {boolean} Does the account have balances?
 */
export function accountHasBalances(account) {
    return isValidObject(account)
         && validNumber(account.aggregationStatusErrorCode) !== 1004;
}

/**
 * @function accountHasType
 * @description Determines if a BAA account has type
 * @param {*} account The BAA account to verify
 * @param {boolean} Does the account have account type?
 */
export function accountHasType(account) {
    return isValidObject(account)
         && isValidString(account.accountType);
}

/**
 * @function accountIsModified
 * @description Determines if a BAA account is modified
 * @param {*} account The BAA account to verify
 * @param {boolean} Is the account modified?
 */
export function accountIsModified(account) {
    return isValidObject(account)
         && isValidString(account.lastUpdated);
}

/**
 * @function isAccountValid
 * @description Determines if a BAA account is valid
 * @param {*} account The BAA account to verify
 * @param {boolean} Is the account valid?
 */
export function isAccountValid(account) {
    return isValidObject(account)
         && isValidString(AGGREGATION_STATUS_ERROR_CODES[account.aggregationStatusErrorCode]);
}

/**
 * @function isAccountAggregated
 * @description Determines if an account was aggregated
 * @param {object} account The account to verify
 * @returns {boolean} Was the account aggregated?
 */
export function isAccountAggregated(account) {
    return isAccountValid(account)
        && [
            ACCOUNT_SOURCES.AGGREGATION,
            ACCOUNT_SOURCES.COMBINED
        ].indexOf(account.source) > -1;
}

/**
 * @function credentialHasAccount
 * @description Determines if a BAA credential has a valid account property
 * @param {*} credential The BAA credential to verify
 * @returns {boolean} Does the credential have a valid account?
 */
export function credentialHasAccount(credential) {
    return isValidObject(credential)
        && isAccountValid(credential.account);
}

/**
 * @function credentialHasLoaded
 * @description Determines if a BAA credential has been loaded
 * @param {*} credential The BAA credential to verify
 * @returns {boolean} Has the credential loaded?
 */
export function credentialHasLoaded(credential) {
    return isValidObject(credential)
        && (accountIsModified(credential.account) || accountHasType(credential.account));
}

/**
 * @function credentialIsComplete
 * @description Determines if a BAA credential is in a complete state
 * @param {*} credential The BAA credential to verify
 * @returns {boolean} Is the credential in a complete state?
 */
export function credentialIsComplete(credential) {
    return isValidObject(credential)
        && (validString(credential.activityInProgressStatus, 'Incomplete') === 'Complete'
        || validString(credential.activityInProgressStatus, 'Incomplete') !== 'In Progress');
}

/**
 * @function getCredentialErrorType
 * @description Determines the error type of a BAA credential
 * @param {*} credential The BAA credential to verify
 * @returns {string} The error type
 */
export function getCredentialErrorType(credential) {
    if (!isValidObject(credential)) {
        return '';
    }

    const {
        authenticationStatusErrorCode,
        unifiedStatusInfoType
    } = credential;

    function arrayContainsInfoType(typeArray) {
        return typeArray.indexOf(unifiedStatusInfoType) > -1;
    }

    switch (true) {
        case (authenticationStatusErrorCode === 1007
            || arrayContainsInfoType(ERROR_MESSAGE_KEYS.USER_RESOLVABLE_TYPE_1)):
            return 'USER_RESOLVABLE_TYPE_1';
        case arrayContainsInfoType(ERROR_MESSAGE_KEYS.USER_RESOLVABLE_TYPE_2):
            return 'USER_RESOLVABLE_TYPE_2';
        case arrayContainsInfoType(ERROR_MESSAGE_KEYS.USER_RESOLVABLE_TYPE_3):
            return 'USER_RESOLVABLE_TYPE_3';
        case arrayContainsInfoType(ERROR_MESSAGE_KEYS.USER_RESOLVABLE_TYPE_4):
            return 'USER_RESOLVABLE_TYPE_4';
        case arrayContainsInfoType(ERROR_MESSAGE_KEYS.USER_RESOLVABLE_TYPE_5):
            return 'USER_RESOLVABLE_TYPE_5';
        case arrayContainsInfoType(ERROR_MESSAGE_KEYS.NOT_USER_RESOLVABLE):
            return 'NOT_USER_RESOLVABLE';
        default:
            return '';
    }
}

/**
 * @function splitAccountName
 * @description Account names contain the last four digits, we want to split them out
 * @param {string} accountName The original account name
 * @returns {object} A collection containing `name` and `number`
 */
export function splitAccountName(accountName) {
    const validName = validString(accountName);
    const matches = ACCOUNT_NUMBER_REGEX.exec(validName);
    let name = accountName;
    let number = '';

    if (matches !== null && matches.length > 3) {
        // eslint-disable-next-line no-unused-vars
        const [suffix, space, exes, lastFour] = matches;

        name = validName.replace(suffix, '');
        number = lastFour;
    }

    return {
        name,
        number
    };
}

/**
 * @function fixDebtValue
 * @description Ensures debts are displayed as negative amounts correctly
 * @param {string} bits The original bits the account was imported from
 * @param {string} displayTypeName The programmatically-determined display type
 * @param {number} value The value received for the account
 * @returns {number} The correct value
 */
export function fixDebtValue({
    bits,
    displayTypeName,
    value
}) {
    if (value <= 0) {
        return value;
    }

    const formattedBits = validString(bits).toLowerCase();
    const hasDebtBits = formattedBits.indexOf('loan') > -1
        || formattedBits.indexOf('debt') > -1
        || formattedBits.indexOf('creditcard') > -1;
    const isDebtType = LIABILITY_TYPES.indexOf(displayTypeName) > -1;

    if (isDebtType || hasDebtBits) {
        return 0 - value;
    }

    return value;
}

/**
 * @function formatAccountTitle
 * @description Generates a formatted string from an `accountName` and `accountType`
 * @param {string} accountName The account name, where provided
 * @param {string} accountType The account type
 * @returns {string} The formatted account title
 */
export function formatAccountTitle(accountName, accountType, ariaDescAccountType) {
    const typeDesc = isValidString(ariaDescAccountType) ? ariaDescAccountType : '';

    if (isValidString(accountName)) {
        return `${accountName}
            ${isValidString(accountType) ? ` <span class="pfm-account-list__bullet" aria-hidden="true">&bull;</span> 
            <span aria-description="${typeDesc}">${accountType}</span>` : ''}`;
    }

    return `${validString(accountType)}`;
}

/**
 * @function formatAccount
 * @description Formats an account received from an API in a consolidated format
 * @param {object} account The account object
 * @param {object} credential The credential object
 * @param {function} formatFn A function to format currency amounts
 * @param {boolean} showPending Should pending status be displayed?
 * @param {string} source The origin of the account
 * @param {function} translateFn A function to translate display types
 * @returns {object} A formatted account object
 */
export function formatAccount({
    account = {},
    credential = {},
    formatFn,
    showPending = true,
    source = 'aggregation',
    translateFn
}) {
    // This is a complete list of properties retrieved from both BAA and Net Worth accounts
    const {
        accountNumber,
        accountType,
        aggregationStatusErrorCode,
        bits,
        category,
        custodianName,
        entryMethod,
        financialItemId,
        financialItemType,
        id,
        importAccountNumber,
        importExternalId,
        importFinancialId,
        lastUpdated,
        marketValue = {
            amount: null
        },
        name,
        owner,
        portfolioId,
        root,
        type,
        value
    } = account;
    // This is a complete list of properties we care about from the BAA credential
    const {
        authenticationStatusErrorCode,
        financialInstitution = {
            id: -1
        },
        // Disambiguate from the `account.id` property
        id: credentialId,
        unifiedStatusInfoType
    } = credential;

    const isBaaAccount = source === 'aggregation';

    // Status codes
    const validAggregationStatus = validNumber(aggregationStatusErrorCode, -1);
    const validAuthenticationStatus = validNumber(authenticationStatusErrorCode, -1);

    // Identifiers
    const baaAccountId = isBaaAccount
        ? validNumber(id)
        : validNumber(importFinancialId, -1);
    const key = `Account_${Math.random()
        .toString(36)
        .substr(2, 9)}`;
    const uimId = validString(portfolioId, financialItemId);
    const validId = isBaaAccount
        ? validNumber(id)
        : validNumber(importFinancialId);

    // Types
    const displayTypeName = isBaaAccount && !accountType
        ? DISPLAY_TYPES.OTHER_ACCOUNTS
        : getDisplayType({
            accountType,
            bits,
            category,
            type
        });
    const displayType = isOfType(translateFn, 'function')
        ? translateFn(displayTypeName)
        : displayTypeName;
    const titleType = getAccountType({
        accountType,
        bits,
        category,
        type
    });
    const validType = validString(accountType, type);

    // Account name and number
    const parsedName = splitAccountName(name);
    const formattedAccountNumber = validString(accountNumber, importAccountNumber);
    const maskedAccountNumber = formattedAccountNumber && formattedAccountNumber.slice(-4);
    const displayAccountNumber = maskedAccountNumber
        ? `...${maskedAccountNumber}`
        : '';

    const ariaDescAccountType = isOfType(translateFn, 'function')
        ? translateFn('ariaDescAccountType')
        : '';

    const title = formatAccountTitle(parsedName.name, titleType, ariaDescAccountType);

    // Custodian and institution
    const institutionId = validNumber(financialInstitution.id);
    const institutionName = validString(financialInstitution.name, custodianName || '&ndash;');

    // Status
    const errorType = getCredentialErrorType(credential);
    const errorButtonLabel = ERROR_BUTTON_LABELS[errorType];
    const hasError = isBaaAccount
        && (aggregationStatusErrorCode !== 1005
        || authenticationStatusErrorCode !== 1005);
    const isInvestment = displayTypeName === DISPLAY_TYPES.INVESTMENTS;
    const isPending = showPending === true
        ? !hasError && displayTypeName === DISPLAY_TYPES.INVESTMENTS
        : false;
    const errorMessage = isOfType(translateFn, 'function')
            && isValidString(institutionName)
        ? translateFn(unifiedStatusInfoType, {
            institutionName,
            loginTerm: financialInstitution.loginTerm,
            pwTerm: financialInstitution.pwTerm
        })
        : '';

    // Values
    const accountValue = validNumber(marketValue.amount, value);
    const fixedValue = accountValue > 0
        ? fixDebtValue({
            bits,
            displayTypeName,
            value: accountValue
        })
        : accountValue;
    const currencyValue = isOfType(formatFn, 'function')
        ? formatFn(fixedValue)
        : fixedValue;

    // Meta
    const linkedTitle = `${institutionName} ${title}${displayAccountNumber}`;

    const formattedAccount = {
        ...credential,
        accountType,
        baaAccountId,
        bits,
        category,
        credentialId,
        currencyValue,
        custodianName,
        displayAccountNumber,
        displayType,
        displayTypeName,
        entryMethod,
        errorButtonLabel,
        errorMessage,
        errorType,
        financialItemId,
        financialItemType,
        hasError,
        importAccountNumber,
        importExternalId,
        importFinancialId,
        institutionId,
        institutionName,
        isInvestment,
        isPending,
        key,
        lastUpdated,
        linkedTitle,
        name,
        owner,
        portfolioId,
        root,
        source,
        title,
        titleType,
        uimId,
        unifiedStatusInfoType,
        accountNumber: maskedAccountNumber || parsedName.number,
        formattedAccountNumber,
        aggregationStatusErrorCode: validAggregationStatus,
        authenticationStatusErrorCode: validAuthenticationStatus,
        id: validId,
        originalValue: accountValue,
        type: validType,
        value: fixedValue
    };

    // Remove the duplicate account details for BAA accounts
    delete formattedAccount.account;

    return formattedAccount;
}

export function formatInstitution({
    account = {},
    institution = {},
    translateFn
}) {
    // This is a complete list of properties retrieved from both BAA and Net Worth accounts
    const {
        accountNumber,
        category,
        custodianName,
        financialItemId,
        financialItemType,
        id,
        lastUpdated,
        name
    } = account;
    // This is a complete list of properties we care about from the BAA credential
    const {
        financialInstitution = {
            id: -1
        },
        // Disambiguate from the `account.id` property
        id: credentialId,
        unifiedStatusInfoType
    } = institution;

    // Account name and number
    const parsedName = splitAccountName(name);
    const formattedAccountNumber = validString(accountNumber);
    const maskedAccountNumber = formattedAccountNumber && formattedAccountNumber.slice(-4);
    const displayAccountNumber = maskedAccountNumber
        ? `...${maskedAccountNumber}`
        : '';

    const ariaDescAccountType = isOfType(translateFn, 'function')
        ? translateFn('ariaDescAccountType')
        : '';

    const title = formatAccountTitle(financialInstitution.name, undefined, ariaDescAccountType);

    // Custodian and institution
    const institutionId = validNumber(financialInstitution.id);
    const institutionName = validString(financialInstitution.name, custodianName || '&ndash;');

    const formattedAccount = {
        ...institution,
        category,
        credentialId,
        custodianName,
        displayAccountNumber,
        financialItemId,
        financialItemType,
        institutionId,
        institutionName,
        lastUpdated,
        name,
        title,
        unifiedStatusInfoType,
        accountNumber: maskedAccountNumber || parsedName.number,
        formattedAccountNumber,
        id
    };

    // Remove the duplicate account details for BAA accounts
    delete formattedAccount.account;

    return formattedAccount;
}

/**
 * @function getCredentialAccounts
 * @description Filters a list of `credential` objects for those with valid `account` values
 * @param {array} credential The list of credentials to parse
 * @returns {array} The parsed array
 */
export function getCredentialAccounts(credentials) {
    if (!isValidArray(credentials)) {
        return [];
    }

    return credentials.filter(credential => {
        const hasAccount = credentialHasAccount(credential);

        if (!hasAccount) {
            return false;
        }

        const hasBalances = accountHasBalances(credential.account);

        // A credential can look like it hasn't loaded but still have an account when import fails
        return !hasBalances
            ? true
            : credentialHasLoaded(credential);
    });
}

/**
 * @function getCredentialsFromResponse
 * @description Parses the BAA accounts API response to retrieve BAA credentials
 * @param {*} apiResponse The BAA accounts API response
 * @returns {array} An array of BAA credentials
 */
export function getCredentialsFromResponse(apiResponse) {
    if (isValidArray(apiResponse)) {
        return apiResponse;
    }

    if (isValidObject(apiResponse) && isValidArray(apiResponse.data)) {
        return apiResponse.data;
    }

    const baaCredentials = validObject(apiResponse, []);

    if (isValidArray(baaCredentials)) {
        return baaCredentials;
    }

    if (isValidObject(baaCredentials) && isValidArray(baaCredentials.data)) {
        return baaCredentials.data;
    }

    return [];
}

/**
 * @function mergeAccounts
 * @description Merges a BAA and a Net Worth account into a single entity
 * @param {object} baaAccount The BAA account
 * @param {object} nwsAccount The Net Worth account
 * @returns {object} The merged account
 */
export function mergeAccounts(baaAccount, nwsAccount, translateFn) {
    const {
        accountType,
        displayAccountNumber,
        institutionName
    } = baaAccount;
    const {
        bits,
        category,
        displayType,
        displayTypeName,
        isInvestment,
        name,
        type,
        financialItemType,
        financialItemId,
        importExternalId,
        importFinancialId,
        importAccountNumber,
        portfolioId
    } = nwsAccount;
    const titleType = getAccountType({
        accountType,
        bits,
        category,
        type
    });

    const ariaDescAccountType = isOfType(translateFn, 'function')
        ? translateFn('ariaDescAccountType')
        : '';

    const title = formatAccountTitle(name, titleType, ariaDescAccountType);
    const linkedTitle = `${institutionName} ${title}${displayAccountNumber}`;

    // We favour the BAA account; NWS provides title and - for investment dccounts - values and dates
    return {
        ...nwsAccount,
        ...baaAccount,
        portfolioId,
        displayType,
        displayTypeName,
        linkedTitle,
        titleType,
        title,
        importFinancialId,
        importAccountNumber,
        importExternalId,
        financialItemType,
        financialItemId,
        currencyValue: isInvestment
            ? nwsAccount.currencyValue
            : baaAccount.currencyValue,
        source: 'combined',
        displayLastUpdated: isInvestment
            ? nwsAccount.displayLastUpdated
            : baaAccount.displayLastUpdated,
        lastUpdated: isInvestment
            ? nwsAccount.lastUpdated
            : baaAccount.lastUpdated,
        value: isInvestment
            ? nwsAccount.value
            : baaAccount.value
    };
}

/**
 * @function resolveAccounts
 * @description Traverses an API response to locate the requested bits
 * @param {array} accountBits A collection of 'bits' to traverse
 * @param {object} root The root object to traverse
 * @returns {array} A collection of located accounts
 */
export function resolveAccounts(accountBits, root) {
    let resolvedAccounts = [];

    if (!Array.isArray(accountBits) || !isValidObject(root)) {
        return resolvedAccounts;
    }

    accountBits.forEach(bits => {
        const newBits = resolveBits({
            bits,
            root
        });

        if (Array.isArray(newBits)) {
            const mappedBits = newBits.map(account => ({
                ...account,
                bits
            }));
            resolvedAccounts = resolvedAccounts.concat(mappedBits);
        }
    });

    return resolvedAccounts;
}

export default null;
