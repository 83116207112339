import baaErrorsEn from 'pfm-shared/labels/baa-errors/en';
import displayTypesEn from 'pfm-shared/labels/display-types/en';
import en from './locales/en.json';

Object.assign(en, baaErrorsEn);
Object.assign(en, displayTypesEn);

export default {
    en
};
