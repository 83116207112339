// eslint-disable-next-line import/no-duplicates
import MdsIconCaretLeft from 'morningstar-design-system/dist/mds.svg?caret-left--s';
// eslint-disable-next-line import/no-duplicates
import MdsIconCaretRight from 'morningstar-design-system/dist/mds.svg?caret-right--s';
// eslint-disable-next-line import/no-duplicates
import MdsIconExclaimCircleFill from 'morningstar-design-system/dist/mds.svg?exclaim-circle-fill--s';
// eslint-disable-next-line import/no-duplicates
import MdsIconTrash from 'morningstar-design-system/dist/mds.svg?trash--s';
import PfmLoader from 'pfm-shared/components/pfm-loader';

export default {
    components: {
        MdsIconCaretLeft,
        MdsIconCaretRight,
        MdsIconExclaimCircleFill,
        MdsIconTrash,
        PfmLoader
    },
    methods: {
        emitSetAccountOfInterest(account) {
            this.$emit('pfm-account-of-interest', account);
        },
        /* istanbul ignore next */
        getAccountsByDisplayType(displayType) {
            return this.allAccounts.filter(account => account.displayType === displayType);
        }
    },
    props: {
        config: {
            default: () => ({}),
            type: [String, Object]
        },
        mwcId: {
            required: true,
            type: String
        }
    }
};
